@import "~styles/variables";

.container {
  width: 330px;
  position: relative;
  max-width: calc(100vw - #{$xl * 2});
}

@include mobile {
  .container {
    width: 100vw;
  }
}

.large {
  width: 400px;
}

.closeBtn {
  position: absolute;
  top: -$xxl;
  right: 0;
}
