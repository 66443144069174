@import "~styles/variables";

.container {
  position: relative;
}

.searchInput {
  @include input;

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &::placeholder {
    color: darken($white, 10%);
  }
}

.options {
  @include card;
  @include transitionify(all);
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 0;
  opacity: 0.5;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.optionsContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.clear {
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}

.clearMessageText {
  font-size: 12px;
}

.message {
  background-color: $blue-1;
  padding: $xs $xxl;
  color: $white;
}

.openOptions {
  height: 250px;
  opacity: 1;

  &.direction-up {
    border-top: $border-light;
  }

  &.direction-down {
    border-top: $border-light;
  }
}

.direction-up {
  bottom: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.direction-down {
  top: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.focusedItem {
  background-color: $gray-3;
}

.arrow {
  pointer-events: none;
  float: right;
  position: relative;
  height: 0;
  width: 0;
  right: $xxl;
  top: 45px;
}

.emptyMessage {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  flex: 1;
  justify-content: center;
  margin-top: 0;
}

.groupHeaderName {
  color: white;
  display: flex;
  font-size: 10px;
  font-weight: bold;
  align-items: center;

  > :first-child {
    margin-right: $s;
  }
}

.groupHeader {
  background-color: $gray-1;
  position: unset;
}

.pulse {
  @include pulse-animation;
}
