@import "~styles/variables";

.tab {
  width: 72px;
  padding: $xs 0;
  border-radius: 20px;
  background-color: transparent;
  text-align: center;
  @include transitionify(background-color);

  &:focus {
    border-radius: 20px;
    box-shadow: none;
    background-color: lighten($gray-3, 20%);
  }

  & + & {
    margin-left: $m;
  }
}

.active {
  composes: tab;
  background-color: $gray-3;
}
