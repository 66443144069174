@import "~styles/variables";

.progressCircle {
  transform: rotate(-90deg);
  @include transitionify(color);
}

.indeterminateProgressCircle {
  animation: indeterminate 1s;
  animation-iteration-count: infinite;
  @include transitionify(color);
}

@keyframes indeterminate {
  0% {
    transform: rotate(-90deg);
  }

  100% {
    transform: rotate(270deg);
  }
}
