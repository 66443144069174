@import "~styles/variables";

.multiSelect {
  box-shadow: none;
  @include transitionify(box-shadow);
  padding: $form-el-top-padding $form-el-side-padding;
}

.openMultiSelect {
  @include focus-glow;
}

.items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.input > input {
  @include input;

  &:focus {
    box-shadow: none;
  }
}

.input {
  width: 100%;
}

.arrow {
  pointer-events: none;
  float: right;
  position: relative;
  height: 0;
  width: 0;
  top: 3px;
  right: 16px;
}

.label {
  display: block;
  margin: 5px 0 3px 2px;
}
