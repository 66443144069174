@import "~styles/variables";

.popup {
  position: fixed;
  display: flex;
  z-index: 4;
  filter: drop-shadow(0px 5px 7px rgba(0, 0, 0, 0.15));

  > .body {
    background-color: $white;

    > .header {
      padding: $s;
      padding-bottom: $xs;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: $gray-1;

      > svg {
        align-self: flex-end;
      }
    }
  }
}

.triangle {
  position: fixed;
  z-index: 4;
  width: 0;
  height: 0;
  border: solid $s;
  border-color: $gray-1 transparent transparent transparent;
}

.button {
  position: relative;
}

.topBorder {
  border-top: 5px solid $teal-1;
}

.bottomBorder {
  border-bottom: 5px solid $teal-1;
}
