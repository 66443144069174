@import "~styles/variables";

.textarea {
  resize: none; // maybe vertical?
  height: 200px;
}

.noHeight {
  height: auto;
}
