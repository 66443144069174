@import "~styles/variables";

.helper {
  padding-bottom: $xl;
}

.helperHighlight {
  display: inline-block;
  color: $pink-1;
  font-family: $Lato-BoldItalic;
  padding-bottom: $xl;
}

.helperSubtitle {
  composes: helper;
  font-family: $WesFY-Black;
}
