@import "~styles/variables";

.trackingBanner {
  padding-left: 35px;
  width: 100%;
}

.trackingTitle {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: start;
  margin-bottom: 12px;
}

.trackingItems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.trackingItem {
  flex: 1;
}

.trackingItem .title,
.trackingItem .primary,
.trackingItem .invariant {
  margin-bottom: 4px;
}

.trackingItem .link {
  font-size: 12px;
  font-weight: normal;
  font-family: $Lato;
  line-height: 14px;
}

.totalDurationItem {
  display: flex;
  flex-direction: column;
  margin-top: 12.5px;

  > :first-child {
    display: flex;
    align-items: baseline;
  }

  .title,
  .primary,
  .invariant {
    font-size: 10px;
  }

  .title,
  .primary {
    font-family: $WesFY-Bold;
  }

  .title {
    color: $gray-3;
  }

  .primary {
    color: $teal-1;
    line-height: unset;
  }
}
