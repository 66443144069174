@import "~styles/variables";

$width: 350px;
$full-height: 250px;
$header-height: 40px;

.bar {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: calc(100vw / 2 - div($width, 2));
  bottom: 0;
  width: $width;
  background-color: $white;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  height: $full-height;
  max-height: 0;
  z-index: 10;
  @include transitionify(max-height);

  &WithFiles {
    max-height: $header-height;
    transition-delay: 1s;
    border: 1px solid $gray-3;
    border-bottom: none;
  }
}

.barWithFiles:hover,
.attention {
  max-height: $full-height;
  transition-delay: 0s;
}

.header {
  padding: 0 $m;
  display: flex;
  align-items: center;
  height: $header-height;
  background-color: $gray-1;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.files {
  flex: 1;
  overflow: auto;
}

.fitem {
  border-bottom: $border-medium;
  padding: $m;
}

.noteWrapper {
  overflow: hidden;
  max-height: 0;
  @include transitionify(max-height);
}

.noteWrapperVisible {
  composes: noteWrapper;
  max-height: 50px;
}

.note {
  composes: fitem;
  background-color: $gray-5;
}

.file {
  composes: fitem;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.iconMargin {
  margin-right: $s;
}

.spinner {
  margin: 0 -10px 0px -15px;
}

.closeHeader {
  align-self: center;
  margin-left: auto;
}
