@import "~styles/variables";

// base

.p {
  font-family: $Lato;
  font-weight: normal;
  margin: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  @include transitionify(color);
}

// variants

.v1 {
  font-size: 22px;
  line-height: 24px;
}

.v2 {
  font-size: 18px;
  line-height: 22px;
}

.v3 {
  font-size: 16px;
  line-height: 20px;
}

.v4 {
  font-size: 14px;
  line-height: 16px;
}

.v5 {
  font-size: 12px;
  line-height: 14px;
}

// kinds

.primary {
  color: $gray-1;
}

.secondary {
  color: $gray-3;
}

.tertiary {
  color: $gray-3;
}

.reverse {
  color: $white;
}

.error {
  color: $pink-1;
}

.pink {
  color: $pink-1;
}

.green {
  color: $teal-1;
}

.royal {
  color: $blue-2;
}

// etc

.inline {
  display: inline;
}

.ellipsis {
  @include ellipsis;
}
