@import "~styles/variables";

.radioButton {
  display: flex;
  align-items: center;

  &:focus {
    outline: 0;

    &:not([aria-disabled]) {
      > .radio > svg > circle:first-child {
        fill: transparentize($blue-2, 0.5);
      }
    }
  }

  &[aria-disabled="true"] {
    opacity: 0.5;

    > * {
      cursor: not-allowed;
    }
  }
}

.radio {
  height: 18px;
  box-sizing: border-box;
  position: relative;

  $mobile-radio-size: 22px;

  > svg {
    @include transitionify(all);
  }

  @include mobile {
    height: $mobile-radio-size;

    > svg {
      height: $mobile-radio-size;
      width: $mobile-radio-size;
    }
  }
}

.innerCircle {
  transform-origin: center;
  @include transitionify(transform);
}

.text {
  margin-left: $s;
  font-size: 14px;
  font-family: $Lato;
  cursor: default;
  color: $gray-1;

  @include mobile {
    font-size: 16px;
  }
}

.reverseText {
  composes: text;
  color: $white;
}
