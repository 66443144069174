@import "~styles/variables";

.select {
  position: relative;
  min-height: 60px;
  box-sizing: border-box;
}

.box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.input > input {
  @include input;

  &:focus {
    box-shadow: none;
  }
}

.darkPlaceholderInput {
  composes: input;
  max-width: 100%;
}

.darkPlaceholderInput > input::placeholder {
  color: $gray-1 !important;
}

.list {
  position: absolute;
  z-index: 10;
  left: 0;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  box-shadow: $shadow;
  background-color: white;
  visibility: hidden;
}

.listToBottom {
  top: 100%;
}

.listToTop {
  bottom: 70px;
}

.listVisible {
  visibility: visible;
}
