@import "~styles/variables";

.listStyle {
  list-style: disc;

  > li {
    margin-bottom: $xs;
  }
}

.tableMargin {
  margin-bottom: $m;
}

.roleTitle {
  font-size: 17px;
}

.note {
  margin-top: $s;
}
