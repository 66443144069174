@import "~styles/variables";

.thinForm {
  width: 230px;
}

.expandedWrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: $m;

  @media screen and (min-width: 900px) {
    grid-template-columns: 1fr;
  }
}

.calendarDropdownSection {
  min-width: 200px;

  @include mobile {
    margin-right: 0 !important;
  }
}

.accordionContainer {
  display: flex;
  flex-direction: column;

  > .header {
    height: 42px;
    display: flex;
    width: 100%;
    background-color: #434a5c;
    align-items: center;

    > .text {
      display: flex;
      flex-direction: row;
      margin: 0 25px;
      justify-content: space-between;
      width: 100%;
    }
  }

  > .body {
    padding: 15px 25px 0 25px;
    width: 100%;
  }
}

.tabContent {
  padding: 15px 0;
}

.tabs {
  display: flex;
  flex-direction: row;
}

.tab {
  flex: 1;
  font-size: 18px;
  font-family: $WesFY-Bold;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 3px;
  border-bottom: 1px solid #46b99c;
  color: $gray-4;
}

.activeTab {
  color: #434a5c;
  border-bottom: 5px solid #46b99c;
}

.scheduleContainer {
  display: flex;
  margin-top: 10px;

  > .timePickers {
    display: flex;
    margin-left: 20px;
    flex: 1;
  }
  > .exchangeIcon {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }
}

.unitPicker {
  display: flex;
  justify-content: center;
  border-left: 1px solid #ecf1f7;
  flex: 1;
  padding: 0 15px;
  height: 70px;
}

.durationContainer {
  display: flex;
  justify-self: flex-end;
  background-color: white;
  flex: 1;
}

.deadlineContainer {
  display: flex;
  flex-direction: column;
  margin-top: -5px;

  > .windows {
    display: flex;
    > :last-child {
      margin-left: 20px;
    }
    > .windowContainer {
      display: flex;
      background-color: $white;
      flex: 1;
      width: 100%;
      flex-direction: column;
    }
  }
}

.startTimeInput {
  background-color: white;
  flex: 1;
}

.dropdown {
  background-color: white;
}

.endDate {
  border-left: 1px solid #ecf1f7;
}

.dates {
  width: 520px;
}

.startEnd {
  display: flex;
  flex: 1;
}

.optional {
  margin-top: 10px;
}

.windowAssignContainer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
  align-items: baseline;
}
