@import "~styles/variables";

.message {
  padding: $m;
}

.readyToAssign {
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.05));
  padding: $v-modal-spacing $xl;
}

.assignmentType {
  margin-bottom: $m;
}

.partnerHi {
  color: $pink-1;
}

.disabledSeeAllPartners {
  opacity: 0.3;
}

.responsibleForExecution {
  display: flex;
  align-items: center;
  gap: $s;
  margin-bottom: $xxl;

  > :last-child {
    margin-top: 2px;
  }
}
