@import "~styles/variables";

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 50px;
}

.center {
  justify-content: center;
  margin-top: 0;
  flex: 1;
}

.tip {
  margin-top: $s;
}
