@import "~styles/variables";

.modal {
  display: grid;

  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    "header header"
    "mainForm sideForm"
    "footer footer";

  @include mobile {
    overflow: auto;
  }
}

.message {
  margin: $m $m 0 $m;
}

.header {
  grid-area: header;
  background-color: $gray-2;
  padding: $xl (2 * $xxxl) $xxxl;

  & .headerContent {
    display: flex;
    flex-flow: row nowrap;

    & .headerContentLeft {
      margin-right: $m;
    }

    & .headerContentRight {
      flex: 1;
    }
  }

  @include mobile {
    padding: $xl $m;
    display: flex;
    align-items: center;

    > .headerContent {
      margin-left: $m;
      flex: 1;
    }
  }
}

.headerBackIcon {
  margin-right: 8px;
}

.headerBack {
  color: $blue-2;
  display: flex;
  padding: 0;
  margin-bottom: 24px;
  align-items: center;

  & .headerBackButton {
    color: $blue-2;
    padding: 0;
    font-size: 16px;
    margin-bottom: -5px;
  }
}

.underline {
  text-decoration: underline;
}

// --- FORM STYLES ---
// These might be moved to a separate component for task editing

.disabledOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  padding: $v-modal-spacing;
  padding-top: 200px;
  text-align: center;

  @include mobile {
    padding-top: 50px;

    > * {
      font-size: 22px !important;
    }
  }
}

.mainForm,
.sideForm {
  background-color: $gray-5;
}

.mainForm {
  grid-area: mainForm;
  box-sizing: border-box;
  padding: $v-modal-spacing;
  overflow: auto;

  @include mobile {
    overflow: visible;
    padding: 0;
    padding-bottom: $xxl;
  }
}

.fieldSection {
  padding: 23px 0;
  display: flex;
  height: 0;
  overflow: hidden;
  position: relative;

  @include mobile {
    padding: $m;
    align-items: center;
  }
}

.fieldSectionActive {
  height: auto;
  overflow: visible;
  margin: unset;
}

.fieldSectionTitle {
  @include mobile {
    display: none;
  }
}

.fieldSectionWarningText {
  padding: 8px;
  background-color: $yellow-3;
  color: $yellow-1;
  border-radius: $border-radius;
}

.fieldSectionContent {
  margin-top: $xxs;
  margin: 0 $m;
  flex: 1;
}

.pndField + .pndField {
  margin-top: $m;
}

.sideForm {
  grid-area: sideForm;
  width: 300px;
  box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}

.confirmTeam {
  padding: $v-modal-spacing $xl;
}

// --- FOOTER ---

.footer {
  @include mobile {
    padding: $l;
    position: relative;
  }

  grid-area: footer;
  padding: $xl $xl $xl $v-modal-spacing;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $gray-1;
  position: relative;
  justify-content: center;
  align-items: center;

  & .button {
    position: static;
    padding: 16px 32px;
    box-sizing: border-box;
    font-size: 24px;

    line-height: unset;
    right: unset;
    top: unset;
    width: unset;
  }

  & .cancelButton {
    font-size: 18px;
    margin-top: 8px;
    text-transform: uppercase;
  }
}

.copies {
  margin-left: $m;

  @include mobile {
    margin-left: 0;
    margin-right: $m;
  }
}

.copiesIcon {
  @include mobile {
    position: absolute;
    top: $xl - $s;
    left: $xl - $s;
    padding-right: 2px;
    z-index: 1;
    background-color: $gray-1;
  }
}

.copiesChevron {
  margin: 0 $xs 0 $m;
}

.programSection {
  margin-top: $s;
}

.button {
  line-height: 5px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 140px;
}

.firstError {
  margin-top: 30px;
}

.errorsContainer {
  overflow: scroll;
}

.error {
  padding: 5px;
  margin: 5px 0;
  border-top: 1px solid $gray-3;
  ul {
    > li {
      margin-top: 5px;
    }
  }
}

.downloadButton {
  display: flex;
  align-items: center;
  justify-content: center;
}
