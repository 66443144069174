@import "~styles/variables";

$button-size: 35px;

.numberBox {
  @include card;
  position: relative;
  width: 135px;
  height: 73px;
  --button-size: #{$button-size};
  --button-color: #{$blue-2};
}

.errorNumberBox {
  composes: numberBox;
  border: 1px solid $pink-1;

  .numberChangeButton {
    background-color: $pink-1;
  }
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: 0;
  border-radius: $border-radius;
  text-align: center;
  font-family: $WesFY-Bold;
  font-size: 24px;

  padding: $xxs $button-size 0 0;
  padding: $xxs var(--button-size) 0 0;

  border: 1px solid $white;

  @include focus;

  &:disabled {
    background-color: $gray-5;
  }

  &::placeholder {
    font-family: $Lato;
  }

  &:focus::placeholder {
    visibility: hidden;
  }
}

.label {
  position: absolute;
  z-index: 1;
  text-align: center;
  font-family: $WesFY-Black;
  font-size: 8px;
  letter-spacing: 0.8px;
  top: $l;
  left: 0;
  right: $button-size;
  right: var(--button-size);

  & + .input {
    padding-top: $m;
  }
}

.arrowButtons {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: calc(100% - #{$button-size});
  left: calc(100% - var(--button-size));
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $white;
}

.numberChangeButton {
  user-select: none;
  background-color: $blue-2;
  background-color: var(--button-color);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1px 0 0;

  @include transitionify(background-color);

  &:first-child {
    margin-top: 1px;
    border-radius: 0 2px 0 0;
    border-bottom: 1px solid $white;
  }

  &:last-child {
    margin-bottom: 1px;
    border-radius: 0 0 2px 0;
  }

  &[disabled] {
    background-color: $gray-3;
  }
}
