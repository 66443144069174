@import "~styles/variables";

.text {
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;
  font-family: inherit;
  color: inherit;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.switch {
  display: none;
  margin-top: $s;
}

.switchVisible {
  composes: switch;
  display: inline-block;
}
