@import "~styles/variables";

.base {
  margin-bottom: $m;
}

.noMargin {
  margin-bottom: 0;
}

.section {
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $shadow;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  & > * {
    border-bottom: $border-light;
  }

  & > *:last-child {
    border-bottom: 0;
  }
}

.spacing-top {
  margin-top: 9px;
}

.section > *:first-child {
  input {
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }

  > *:first-child input {
    border-top-left-radius: $border-radius;
    border-top-right-radius: 0;
  }

  > *:last-child input {
    border-top-left-radius: 0;
    border-top-right-radius: $border-radius;
  }
}

.section > *:last-child {
  input {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  > *:first-child input {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: 0;
  }

  > *:last-child input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: $border-radius;
  }
}

.description {
  margin-top: 4px;
  margin-bottom: $m;
}
