@import "~styles/variables";

.detailBlocks {
  flex: 1;
}

.grid {
  display: grid;
  grid-template-columns: 35% 65%;

  > * {
    border-bottom: 1px solid $gray-4;

    &:nth-child(2n) {
      border-left: 1px solid $gray-4;
    }
  }

  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    > * {
      border-bottom: unset;

      &:nth-child(2n) {
        border-left: unset;
      }

      &:last-child {
        border-bottom: 1px solid $gray-4;
      }
    }
  }
}

.start {
  align-items: flex-start;
}

.header {
  background-color: $gray-1;
}

.detailsForm {
  min-width: 300px;

  > div > [role="radio"] {
    margin-bottom: $m;
  }
}
