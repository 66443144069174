@import "~styles/variables";

$field-height: 78px;

.findNew {
  text-align: right;
  padding-bottom: $s;
}

.resetButton {
  padding: $s;
  @media (max-width: 540px) {
    padding: $s 0px;
  }
}

.noSavedLocations {
  display: flex;
  align-items: center;
}

.reset {
  text-align: right;
}

.filterHeader {
  display: flex;
  justify-content: space-between;
  padding: 0px $s;
  @media (max-width: 540px) {
    padding: 0;
  }
}

.loadingContainer {
  margin-top: unset;
  margin-bottom: $xl;
}

.radius {
  flex: 0.6;
  display: flex;
  input[type="number"] {
    border: unset;
    box-shadow: unset;
    flex: 0.5;
  }
}

.productsCell {
  display: flex;
  flex-direction: column;
}

.search {
  flex: 0.15;
}

.miles {
  flex: 0.4;
  :invalid {
    border: 2px solid $pink-1;
  }
}

.url {
  font-size: 14px !important;
  line-height: 18px !important;
  color: $blue-2 !important;
  font-family: $WesFY-Bold !important;
  text-decoration: none !important;
}

.moreFilters {
  background-color: $blue-2;
  > div {
    color: white !important;
  }
}

.table {
  margin-bottom: $m;
}

.tableHeader {
  top: -35px !important;
}

.mobileRow {
  margin-bottom: $s;
  box-shadow: $shadow;
}

.locationCard {
  margin-bottom: $m;
}

.tableFilters {
  > span {
    background-color: transparent;
  }
}

.filters {
  background-color: white;
}

.geosuggest {
  display: block;
  margin-right: 0 !important;
  color: white;
  background-color: white;

  :global(.geosuggest__input-wrapper) {
    height: 70px;
  }

  :global(.geosuggest__suggests) {
    top: 10px;
  }
}

.geosuggestText {
  :global(input) {
    color: $gray-1 !important;
    background-color: transpa;
  }
}
