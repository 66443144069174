@import "~styles/variables";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  @include safe-top(margin);

  @include print {
    position: unset;
  }
}

.transparent {
  background-color: transparent;
}

.content {
  @include card;
  position: relative;
  padding: 32px 25px;
  flex: 1 1 100%;
  z-index: 3;
  min-height: 340px;
  max-width: 600px;
  max-height: 600px;
  outline: none;
  overflow: auto;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;

  @include mobile {
    display: block;
    max-height: 100%;
    height: 100%;
    max-width: 100vw;
    width: 100vw;
    box-sizing: border-box;
    border-radius: 0;
  }
}

.noPadding {
  padding: 0;
}
