@import "~styles/variables";

.documentName {
  @include ellipsis;
  max-width: 190px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}
