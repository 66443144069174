@import "~styles/variables";

$st-border-radius: 10px;

.loadingPlaceholder {
  padding: $xxl;
}

.fieldReportIframe {
  border: 0;
  width: 100%;
}

.generalStats {
  padding: 0 $xxl;
  flex: 1;
  position: relative;
}

.multipleDownload {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: $xl;

  :last-child {
    margin-left: $s;
  }
}

.questionGroup {
  display: flex;
  padding: $xl 0;

  @include print {
    padding: $xs 0;
    page-break-inside: avoid;
  }
}

.mobileQuestionGroup {
  flex-direction: column;
  padding: $m 0 0;
}

.sidebar {
  width: 25%;
  margin-right: $l;
}

.mobileSidebar {
  width: unset;
  margin-bottom: $xs;
}

.sidebarTitle {
  line-height: 18px;
  margin-bottom: $s;
}

.sidebarQuestion {
  font-size: 12px;
  margin-bottom: $s;
}

.sidebarAnswer {
  margin-bottom: $xs;
  overflow-wrap: break-word;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.sibling {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $s;
}

.variableSibling {
  flex: 0 1 auto;
  flex-direction: row;
}

.siblingLabel {
  color: white;
  font-size: 10px;
}

.secondaryLabel {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: $xs;
}

.productLabel {
  font-size: 12px;
  margin-bottom: $xs;
}

.simpleAnswers {
  display: inline-flex;
  flex-wrap: wrap;
  > div {
    margin-bottom: $m;
  }
  :not(:last-child) {
    margin-right: $m;
  }
}

.customItemBorder {
  &:first-child {
    border-top-left-radius: $st-border-radius;
    border-bottom-left-radius: unset;
  }

  &:last-child {
    border-top-right-radius: $st-border-radius;
    border-bottom-right-radius: unset;
  }
}

.statsBoard {
  margin-bottom: $s;
}

.withMargin {
  margin-bottom: 3px;
}

.underline {
  border-top: rgba(0, 0, 0, 0.15) 1px solid;
  width: 100%;
}

.expenseAmount {
  margin-bottom: $s;
}

.expenseItem {
  display: flex;
  margin-bottom: $m;
}

.missingLabel {
  color: $pink-1;
}

.imagesWrapper {
  display: flex;
}

.imagesWrapper {
  display: flex;
  flex-wrap: wrap;
}
