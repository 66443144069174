@import "~styles/variables";

.popupContainer {
  padding: $xl;
}

.container {
  padding: $l;
  max-width: 300px;
}
