@import "~styles/variables";
@import "~styles/bg-pattern";
@import "../../dashboard";

.topNav {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  box-sizing: border-box;
  min-height: 75px;
  position: fixed;
  width: 100%;
  z-index: 10;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $tablet) {
    min-height: 60px;
  }

  padding-right: var(--fake-scrollbar, 0px);
}

.newTopNav {
  @extend .topNav;
  box-shadow: 0px 2px 10px 0px var(--primary-color);
  background: linear-gradient(to right, var(--primary-color) -20%, #272f3f 15%, #272f3f 100%);
  padding: $l;
  min-height: 64px;
  max-height: 64px;

  @media (max-width: $desktop) {
    background: linear-gradient(to right, var(--primary-color), #272f3f 45%);
  }

  > .navRight {
    display: flex;
    gap: $l;
    align-items: center;
    position: relative;

    > .menuContainer {
      position: relative;
      display: inline-block;
      > button {
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
        position: relative;
        display: flex;

        > svg {
          fill: white;
        }
      }
      > .dropdownContainer {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        z-index: 10;
        width: max-content;

        @media (max-width: $tablet) {
          right: 0;
        }
      }
    }

    > .currentUserDetailsContainer {
      display: flex;
      gap: 5px;
    }
  }
}

.contentWrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: $max-page-width;
  padding: 0 50px;
  width: 100%;

  @media (max-width: $tablet) {
    padding: 0 $m;

    &.pickers {
      display: unset;
    }
  }
}

.whiteLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.goToAdmin {
  margin-bottom: $s;
}

.impersonating {
  display: flex;
  align-items: flex-end;
  margin-top: $xs;
}

.orgAndLogo {
  display: flex;
  max-height: 26px;

  @media (max-width: $desktop) {
    flex-direction: column;
  }

  @media (max-width: $tablet) {
    align-self: center;
  }
}

.alignOrg {
  align-self: flex-end;
  margin-right: $xs;

  @media (max-width: $desktop) {
    align-self: flex-start;
    margin-right: unset;
  }
}

.poweredBy {
  @media (max-width: $desktop) {
    margin-top: 3px;
  }
}

.accountAndGigs {
  display: flex;
  align-items: center;

  > div {
    padding-left: $m;
  }

  @media (max-width: $tablet) {
    flex-direction: row-reverse;
  }
}

.menu {
  margin-top: $s;
}

.menuContent {
  width: 160px;
}

.menuItem {
  border-bottom: $border-medium;
  display: block;
  width: 100%;
  text-align: right;
  border-radius: 0;
  @include transitionify(background-color);

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.topNavShadow {
  box-shadow: $shadow;
}

.children {
  @include bg-pattern(#111827, #273247);
  color: $white;
  display: flex;
  margin-top: 75px;
  padding: $xxl 0;

  @media (max-width: $tablet) {
    display: unset;
    margin-top: 60px;
    padding: 30px $m;
  }
}

.newNavChildren {
  margin-top: 60px;
}

.organizationPicker {
  flex: 1;
  margin-right: $s;

  input {
    padding: 0;
  }
}

.programPicker {
  flex: 2;

  @media (max-width: $tablet) {
    padding-top: $s !important;
  }
}

.orgInput {
  font-size: 21px !important;
  font-weight: 900;
  color: $gray-1 !important;
  font-family: $WesFY-Black !important;
}

.tabBackground {
  background-color: $gray-3;
}

.tabs {
  background-color: $gray-3;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.tab {
  width: 200px;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.mobileTab {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    margin-top: $xs;
  }
}

.activeTab {
  align-self: flex-end;
  background-color: $gray-5;
  height: 110%;
}

.label {
  cursor: pointer;
}

.disabledLabel {
  cursor: not-allowed;
  color: $gray-3;
}

.mailto {
  padding: 13px 18px;
  width: auto;
  line-height: 14px;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $s;

  > button {
    gap: $s;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    > img {
      max-height: 40px;
    }
    > svg {
      fill: white;
    }
  }

  > .breadcrumb {
    // wesfy required fix
    margin-bottom: -1px;
  }
}

.topNavActionButton {
  border-radius: $xs;
}
