@import "~styles/variables";

.content {
  width: 470px;
}

.modal {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  :global(.nb.a) {
    z-index: 999;
  }
}
