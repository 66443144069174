@import "~styles/variables";

.tableFilters > div:first-child {
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.goButton {
  flex: 0 !important;
  min-width: 50px;
  margin: 2px;
}

.transparent {
  background-color: transparent;

  > div {
    background-color: inherit;
    box-shadow: none;
  }
}
