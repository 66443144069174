@import "~styles/variables";

$button-size: 44px;

.roundButton {
  border: 1px solid white;
  border-radius: 50%;
  width: $button-size;
  height: $button-size;
  display: flex;
  justify-content: center;
  align-items: center;
  @include transitionify(background-color);
}

.active {
  background-color: white !important;
}

.roundButton:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
