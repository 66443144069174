@import "~styles/variables";

$border: 1px solid $gray-4;

.accSection {
  z-index: 1;
  display: flex;
  flex-direction: column;

  @include mobile {
    margin-bottom: $xs;
    border-bottom: 1px solid $gray-5;
  }
}

.titleAndHeader {
  position: sticky;
  z-index: 2;
  top: 0;
  border-top: $border;

  @include mobile {
    justify-content: space-between;
    align-items: center;
    padding: 0 $m;

    &.inactive {
      background-color: unset;
    }
  }
}

.minimalTitleAndHeader {
  border-top: 0;
}

.inactive {
  border: none;
}

.headerAndExpander {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;
  position: relative;
}

.headerTitle {
  display: block !important;
}

.minimalHeaderBorder {
  position: absolute;
  top: 0;
  left: $xl;
  right: 0;
  border-top: $border;
}
