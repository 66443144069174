@import "~styles/variables";

.working {
  position: relative;
}

.working::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: " ";
  background: linear-gradient(90deg, transparent, transparent, rgba(0, 0, 0, 0.07), transparent, transparent);
  background-size: 300% 100%;
  animation: working 2s linear infinite reverse;
  overflow: hidden;
  border: 1px solid transparent;
}

@keyframes working {
  0% {
    background-position-x: -100%;
  }
  100% {
    background-position-x: 200%;
  }
}
