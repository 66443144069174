@import "~styles/variables";

$arrow-right-offset: 35px;

.dropdown {
  appearance: none;
  padding-right: $arrow-right-offset;
  color: $gray-1;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: $Lato;

  option:disabled {
    color: $gray-3;
  }

  &:active,
  &:focus {
    border-color: rgba(0, 0, 0, 0);
  }
}

.with-label {
  top: 40px;
}
