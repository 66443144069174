@import "~styles/variables";

$avatar-xl-size: 90px;
$avatar-size: 50px;
$avatar-sm-size: 40px;

.avatar {
  width: $avatar-size;
  height: $avatar-size;
  border-radius: 50%;
  background-color: $gray-1;
  background-size: cover;
  background-position: center;

  > svg {
    height: $avatar-size;
  }
}

.square {
  border-radius: 0;
}

.noImage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.small {
  width: $avatar-sm-size;
  height: $avatar-sm-size;

  > svg {
    height: $avatar-sm-size;
  }
}

.xl {
  width: $avatar-xl-size;
  height: $avatar-xl-size;

  > svg {
    height: $avatar-xl-size;
  }
}
