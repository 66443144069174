@import "~styles/variables";

@mixin mobile-line-child {
  @include mobile {
    flex: unset;
    background: $gray-1;
    color: $white;
    padding-right: $xs;
    display: block;
  }
}

.line {
  display: flex;
  background-color: $gray-5;

  @include mobile {
    background-color: $gray-1;

    &.inactive {
      background-color: unset;
    }
  }
}

.line > * {
  padding: $xl 0;

  @include mobile {
    font-size: 18px;
  }
}

.line > :first-child:not(:last-child) {
  padding-right: $xl;
  background: $side-bar-gradient-left;
  flex: 0.45;

  @include mobile-line-child;
}

.line.inactive > :first-child:not(:last-child),
.line.inactive > :last-child {
  @include mobile {
    background: unset;
    color: $gray-3;
    font-size: 14px;
    font-family: $WesFY-Bold;
  }
}

.line > :last-child {
  padding-left: $xl;
  flex: 1;

  @include mobile-line-child;
  @include mobile {
    padding-left: 0;
  }
}

.hideHelpers > :first-child:not(:last-child) {
  display: none;
}

.hideHelpers > :last-child {
  padding-left: 0;
}
