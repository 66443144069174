@import "~styles/variables";

.reportOverview {
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: -$m;
  position: relative;
}

.viewReport {
  padding-top: $m;
}

.viewEdit {
  position: absolute;
  top: -31px;
  left: 103px;
  z-index: 2;
}

.editDate {
  left: 150px;
}
