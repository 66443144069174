@import "~styles/variables";

$item-spacing: $xs;

.group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -$item-spacing;

  > * {
    margin-bottom: $item-spacing;
    margin-right: $item-spacing;
  }
}

.vertical {
  flex-direction: column;
}

.horizontal {
  flex-direction: row;
}
