@import "~styles/variables";

.expensePolicy {
  background-color: $gray-1;
  padding: $l 50px;
}

.expenseContainer {
  padding: $xl 50px $xl $xl;
  max-width: 320px;
}

.addReceipt {
  border-color: $blue-2;
  background-color: $blue-2;
  color: white;
  margin-right: unset;
}

.totalExpenses {
  display: flex;
  justify-content: space-between;
  margin-top: $xxl;
}

.totalValueExpenses {
  display: flex;
  justify-content: space-between;
  margin-top: $m;
}

.expensesSeparator {
  width: 100%;
  height: 1px;
  margin: 40px 0;
  background-color: $gray-1;
}

.addReceiptItems {
  display: flex;
  align-items: center;
  > svg {
    margin-right: $s;
  }
}

.expenseList {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-start;
  > :not(:first-child) {
    margin-top: $l;
  }
}

.reverseSwitch {
  flex-direction: row-reverse;
}

.switchText {
  color: $gray-1;
  margin-right: unset;
  margin-left: $s;
  font-family: $WesFY-Black;
  font-size: 14px;
}

.addExpenseContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: $xxl;
}

.addExpenseButton {
  margin-top: $m;
}

.errorBanner {
  background-color: $pink-1;
  padding: 20px;
}
.submitError {
  margin: 0 0 $s;
}

.deleteExpenseConfirmation {
  display: flex;
  height: 100%;
  :first-child {
    margin-top: 20px;
  }
}
