@import "~styles/variables";

$side-padding: $form-el-side-padding;
$top-padding: $form-el-top-padding;
$required-right-offset: 30px;

.formElement {
  position: relative;
  min-height: 46px;
}

.withLabel {
  min-height: 70px;
}

.label {
  position: absolute;
  z-index: 0;
  left: $form-el-side-padding;
  right: $form-el-side-padding;
  padding-top: $form-el-top-padding;
  padding-bottom: 2px;
  pointer-events: none;
  background-color: $white;
  top: 0;
  white-space: nowrap;
}

.labelReverse {
  background-color: transparent;
}

.input {
  @include input;
  padding: $top-padding $side-padding;
  box-sizing: border-box;
  transition: $base-duration;
  width: 100%;
  float: left;

  &:not(:focus) ~ .label {
    top: 2px;
    padding-top: $form-el-top-padding - 2px;
  }
}

.spacing-top {
  padding-top: $top-padding + 20px;
}

.required {
  pointer-events: none;
  position: absolute;
  margin-left: -$required-right-offset;
  margin-top: $top-padding;
}

.textarea {
  resize: none;
  height: 200px;
}

.error {
  box-sizing: border-box;
  // important because children selectors on horizontal group is
  // more specific, and can remove the left or right border here.
  border: 1px solid $pink-1 !important;
  border-radius: $border-radius;

  > input:focus {
    box-shadow: none;
  }
}

.error-msg > * {
  display: inline-block;
  padding: 0 $side-padding;
  padding-bottom: $s;
  margin-top: 0;
}

.optionalLabel {
  display: inline-block;
  font-family: $Lato;
  font-style: italic;
  font-size: 12px;
  color: $gray-3;
  user-select: none;
}

.lock {
  margin-left: $xs;
}

.arrow {
  pointer-events: none;
  position: absolute;
  // height: 0;
  // width: 0;
  right: $side-padding;
  top: calc(50% - 5px);
}
