@import "~styles/variables";

.label {
  cursor: inherit;
  font-family: $WesFY-Bold;
  font-size: 11px;
  color: $gray-1;
  letter-spacing: normal;
}

.reverse {
  color: $white;
}
