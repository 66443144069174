@import "~styles/variables";

.nav {
  font-family: $WesFY-Bold;
  font-size: 8px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}

.primary {
  color: $gray-1;
}

.secondary {
  color: $gray-3;
}
