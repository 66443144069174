$fonts: "https://static.gopinata.com/fonts";

@font-face {
  font-family: "WesFY-Black";
  src: url("#{$fonts}/WesFY/WesFy-black.woff") format("woff"), url("#{$fonts}/WesFY/WesFy-black.woff2") format("woff2"),
    url("#{$fonts}/WesFY/WesFy-black.ttf") format("ttf"), url("#{$fonts}/WesFY/WesFy-black.eot") format("eot");
}

@font-face {
  font-family: "WesFY-Bold";
  src: url("#{$fonts}/WesFY/WesFy-bold.woff") format("woff"), url("#{$fonts}/WesFY/WesFy-bold.woff2") format("woff2"),
    url("#{$fonts}/WesFY/WesFy-bold.ttf") format("ttf"), url("#{$fonts}/WesFY/WesFy-bold.eot") format("eot");
}

@font-face {
  font-family: "WesFY-Regular";
  src: url("#{$fonts}/WesFY/WesFy-regular.woff") format("woff"),
    url("#{$fonts}/WesFY/WesFy-regular.woff2") format("woff2"), url("#{$fonts}/WesFY/WesFy-regular.ttf") format("ttf"),
    url("#{$fonts}/WesFY/WesFy-regular.eot") format("eot");
}

@font-face {
  font-family: "Lato";
  src: url("#{$fonts}/Lato/Lato-Regular.woff") format("woff"), url("#{$fonts}/Lato/Lato-Regular.woff2") format("woff2"),
    url("#{$fonts}/Lato/Lato-Regular.ttf") format("ttf"), url("#{$fonts}/Lato/Lato-Regular.eot") format("eot");
}

@font-face {
  font-family: "Lato-Heavy";
  src: url("#{$fonts}/Lato/Lato-Heavy.woff") format("woff"), url("#{$fonts}/Lato/Lato-Heavy.woff2") format("woff2"),
    url("#{$fonts}/Lato/Lato-Heavy.ttf") format("ttf"), url("#{$fonts}/Lato/Lato-Heavy.eot") format("eot");
}

@font-face {
  font-family: "Lato-Italic";
  src: url("#{$fonts}/Lato/Lato-Italic.woff") format("woff"), url("#{$fonts}/Lato/Lato-Italic.woff2") format("woff2"),
    url("#{$fonts}/Lato/Lato-Italic.ttf") format("ttf"), url("#{$fonts}/Lato/Lato-Italic.eot") format("eot");
}

@font-face {
  font-family: "Lato-BoldItalic";
  src: url("#{$fonts}/Lato/Lato-BoldItalic.woff") format("woff"),
    url("#{$fonts}/Lato/Lato-BoldItalic.woff2") format("woff2"), url("#{$fonts}/Lato/Lato-BoldItalic.ttf") format("ttf"),
    url("#{$fonts}/Lato/Lato-BoldItalic.eot") format("eot");
}

@font-face {
  font-family: "Lato-Bold";
  src: url("#{$fonts}/Lato/Lato-Bold.woff") format("woff"), url("#{$fonts}/Lato/Lato-Bold.woff2") format("woff2"),
    url("#{$fonts}/Lato/Lato-Bold.ttf") format("ttf"), url("#{$fonts}/Lato/Lato-Bold.eot") format("eot");
}

// Don't import more WesFy or Lato fonts unless we need them. These fonts are heavy.
