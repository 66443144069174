.checkboxLabel {
  white-space: unset;
  margin-top: 20px;
}

.inputCls {
  background: white;
}

.agreementContainer {
  display: flex;
  flex-direction: column;
}
