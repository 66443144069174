@import "~styles/variables";

.loadingPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  min-width: 900px;
  max-width: 1300px;
  max-height: calc(100vh - #{$xl});
  min-height: calc(100vh - #{$xl});
}

.helpContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $gray-2;
  margin: $m 0;
  border-radius: $border-radius;
  justify-content: space-between;
  text-align: left;
  padding: $m;
  overflow: hidden;
}

.helpOpen {
  height: 100%;
}

.helpClosed {
  height: 0;
}

.helpResources {
  display: flex;
  flex-direction: column;
  padding: $m $l;
  padding-right: 0;
  gap: $m;
  list-style-type: circle;
  & > li::marker {
    margin-left: $s;
    color: $blue-2;
  }

  & li > a {
    display: block;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
