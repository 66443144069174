@import "~styles/variables";

$control-size: 24px;

.fileOptions {
  display: flex;
  height: $control-size;
  align-self: flex-end;
  margin-bottom: $xs;
}

.control {
  width: $control-size;
  height: $control-size;
  cursor: pointer;
}

.downloadBtn {
  composes: control;
  margin-right: $s;
}

.copyLinkBtn,
.openImageBtn {
  composes: control;

  &:focus {
    outline: 0;
  }
}

.copyLinkBtn {
  margin-right: $s;
}
