.container {
  height: 100%;
  width: 100%;
}

.portalContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.25);
}
