@import "~styles/variables";
@import "../../Dashboard/dashboard";
@import "ColumnHeader/styles";

$actions-width: 40px;
$rows-margin: $xs;

.table {
  flex: 1;
  position: relative;
  margin-bottom: 70px;

  > ::-webkit-scrollbar {
    display: none;
  }
}

.stickyHeader {
  position: sticky;
  top: 75px;
  z-index: 2;
}

.embeddedStickyHeader {
  top: 0;
}

.filterBar {
  background-image: $bar-gradient-up-down;
}

.selectAll {
  background-image: $bar-gradient-up-down;
  width: 40px;
  display: flex;
  justify-content: center;
  // borderRight: "1px solid white",
}

.tableHeader {
  display: flex;
  background-color: $gray-3;
  border-left: 1px solid white;
  border-right: 1px solid white;
  top: 0;
  z-index: 3;
  margin: 0 auto;
  max-width: $desktop + 200;
  > :nth-child(2) {
    border-left: 1px solid white;
  }
}

.clearFilters {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  @include transitionify(background-color);

  border-bottom-right-radius: $border-radius;
  border-top-right-radius: $border-radius;

  box-sizing: border-box;
  width: $actions-width;
}

.clearFiltersActive {
  composes: clearFilters;
  background-color: $teal-1;

  &:hover,
  &:focus {
    background-color: $pink-1;
  }
}

.tableHeaderShadow {
  margin-top: -2px;
  height: 0;
  border-bottom: 8px solid $gray-1;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  transform: rotate(180deg);
}

.rows {
  box-sizing: border-box;
  position: relative;
  overflow: overlay;
  // scrollbar-width: none;

  // &::-webkit-scrollbar {
  //   margin-right: 10px;
  //   z-index: 10;
  // }

  &:focus {
    outline: 0;
  }
}

$error-width: 300px;
$error-height: 150px;

.errorWrapper {
  position: absolute;
  top: 45px;
  left: $rows-margin + 1;
  right: $rows-margin + 1;
  border-radius: 7px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.error {
  width: $error-width;
  height: $error-height;
  border-radius: $border-radius;
  background-color: $white;
  box-shadow: $shadow;
  padding: $l;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.errorHeader {
  display: flex;
  justify-content: space-between;
}

.tryAgain {
  align-self: flex-end;
}

.actionBar {
  width: 100%;
  background-color: $gray-1;
  display: flex;
  position: fixed;
  box-sizing: border-box;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.5s ease-in;
  padding-right: var(--fake-scrollbar, 0px);
}

.showActions {
  bottom: 0;
  transform: translate3d(0, 0, 0);
}

.contentWrapper {
  margin: 0 auto;
  max-width: $max-page-width;
  padding: 15px 50px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;

  > div {
    margin-left: $m;
    flex-wrap: wrap;
    gap: $s 0;
  }

  @include mobile {
    padding: $m $m;
  }
}

.bottomBar {
  justify-content: left;
}

.actions {
  display: flex;
}

.thinButton {
  margin-left: $s;
  button {
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    svg {
      margin-right: $xs;
    }
  }

  @include mobile {
    padding: 0;
    button {
      padding: 10px;
      svg {
        margin-right: 0;
      }
    }
  }
}

.actionPopup {
  background-color: $gray-1;
}

.actionContent {
  padding: $m;
  background-color: $gray-1;
}
.actionSubmit {
  margin-top: $m;
  width: 100%;
  box-sizing: border-box;
}

.partialRun {
  background-color: $pink-1;
  padding: $m;
  display: flex;
  align-items: center;
}

.batchActionInfoHeader {
  background-color: $blue-2;
  padding: $m;
  display: flex;
  align-items: center;
}

.warnIcon {
  opacity: 0;
  margin-left: $xs;
  @include transitionify(opacity);
}

.warnIconVisible {
  composes: warnIcon;
  opacity: 1;
}

.warnIconMobile {
  position: absolute;
  border-radius: 5px;
  right: -5px;
  top: -5px;
  width: 15px;
  height: 15px;
  z-index: 10;
}
