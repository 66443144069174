@import "~styles/variables";
@import "../../../dashboard";

.modal {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background-color: $gray-5;

  @include tablet-desktop {
    max-height: 100%;
  }
}

.content {
  flex: 1;
  padding: $modal-spacing;
  overflow-y: auto;
}

.copy {
  margin-bottom: $m;
}

.locationAddress {
  line-height: 20px;
  background-color: $green;
}

.locationIdentifier {
  flex: 0.45;
}

.sectionTitle {
  margin-bottom: $s;
}

// Location Section

.geosuggest {
  display: block;
  margin-right: 0 !important;
  color: white;

  :global(.geosuggest__input-wrapper) {
    height: $field-height;
  }
}

.geosuggestText {
  :global(input) {
    color: white !important;
  }
}

.idTextBox {
  flex: 0.35;
}

.noMinWidth {
  min-width: unset;
}

.labelTitle {
  font-size: 10px;
  font-family: $WesFY-Bold;
}

.labelOptional {
  font-size: 10px;
}

.checkbox {
  padding: $s 0 16px 0px;
}

a.url {
  color: $blue-2;
}
