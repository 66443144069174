@import "~styles/variables";

.saveSection {
  margin-top: $m;
}

.noMargin {
  margin-right: 0 !important;
}

.inContentAddProduct {
  float: right;
  margin-top: $m;
}
