@import "~styles/variables";
@import "../../../dashboard";

.main {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
  height: fix-vh(100vh);
}

.content {
  @include max-page-width;
  width: 100%;
  flex: 1;
}

.title {
  padding: 50px 50px $xl 50px;
  font-size: 40px;
}

.complete {
  color: $teal-1;
}

.optional {
  margin-left: $s;
  font-family: $Lato-BoldItalic;
}

.sectionLoading {
  margin: 0;
  height: 300px;
}

.lock[data-tooltip]::after {
  top: calc(100% + #{$xxs});
}

.instructionsHeader {
  flex-direction: column;
}

.requiredContainer {
  display: flex;
}

.required {
  color: $pink-1;
  margin-right: 8px;
}
