@import "~styles/variables";

.valueSource {
  padding: 0 $m $m;
}

.sourceSection > div {
  margin-bottom: 0;
}

.geosuggest {
  display: block;
  margin-right: 0 !important;

  :global(.geosuggest__input-wrapper) {
    height: 47px;
  }
}

.basicMoney {
  height: 40px;
  width: unset;
  box-shadow: unset;
  div,
  input {
    font-size: 14px;
  }
}
