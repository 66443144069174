@import "~styles/variables";

.accPart {
  display: flex;
  margin-top: -$xl + 2px; // Those 2 pixels are for shadows and overflowing content
}

.accPart:not(:nth-child(2)) > * {
  padding-top: 0;
}
