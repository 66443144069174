@import "~styles/variables";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);

  @include safe-top(margin);

  @include print {
    position: unset;
  }
}

.content {
  @include card;
  position: relative;
  flex: 1 1 100%;
  z-index: 3;
  outline: none;

  max-width: 100vw;
  max-height: 100vh;

  @include mobile {
    display: block;
    max-height: 100%;
    height: 100%;
    max-width: 100vw;
    width: 100vw;
    box-sizing: border-box;
    border-radius: 0;
  }
}

$close-size: 32.5px;

.closeButton {
  position: absolute;
  top: 0;
  left: 0;
  width: $close-size;
  height: $close-size;
  padding: 7px;
  z-index: 2;
  background-color: $gray-1;
  border-bottom-right-radius: 100%;
  box-sizing: border-box;

  &:focus {
    border-bottom-right-radius: 100%;
    background-color: darken($gray-1, 10%);
  }
}
