@import "~styles/variables";

$action-size: 52px;
$list-action-size: 84px;
$row-height: 64px;
$row-height-small: 32px;
$table-header-size: 34px;
$header-color: $gray-3;
$expand-color: $blue-3;
$expand-bg-color: $blue-4;
$focus-expand-color: darken($expand-color, 20%);
$side-padding: $m;

@mixin list-mode {
  &.listMode {
    @content;
  }
}

.table {
  position: relative;

  @include mobile {
    min-width: 100%;
  }
}

.listHeader {
  display: flex;
  justify-content: flex-end;
  padding: $m;
  box-sizing: border-box;
  background-color: $gray-5;
  position: sticky;
  z-index: 1;
  top: 0;
  @include safe-top(top);
}

.fixedHeightContent {
  display: flex;
  flex-direction: column;
  background-color: $gray-5;
}

.rows {
  flex: 1;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

._row {
  position: relative;
  display: flex;
  border-bottom: $border-medium;
}

.rowWrapper {
  display: flex;
  flex-direction: column;
}

.row {
  composes: _row;
  background-color: $white;
  @include list-mode {
    height: auto;
    border: 0;
    padding: $m;
    margin-bottom: $m;
  }
}

.expandableRowActions {
  margin-bottom: $m;
}

.expandedRow {
  background-color: $expand-bg-color;
  border: 0;
}

.rowExpandedContent {
  background-color: $expand-bg-color;
  padding: 0 $side-padding;
  position: relative;
}

.expandedContentMargin {
  margin-bottom: $m;
}

.rowExpandedTable {
  border-collapse: collapse;
}

.labelTd {
  min-width: 65px;
}

.contentTd > .cell {
  // IE11
  display: block;
}

.colLabel {
  font-weight: bold;
}

.expandedActionsContainer {
  display: flex;
  margin: $m 0;
}

.expandedAction {
  margin-right: $m;
  font-weight: bold;
  font-family: $WesFY-Bold;
  text-transform: uppercase;
}

.rowCells {
  display: flex;
  flex: 1;
  width: 100%;

  @include list-mode {
    flex-direction: column;
  }
}

.header {
  min-height: $table-header-size;
  height: $table-header-size;
  background-color: $header-color;
  position: sticky;
  z-index: 1;
  top: 0;
  @include safe-top(top);

  .cell {
    top: 1px;
    font-family: $WesFY-Bold;
    color: $white;
    font-size: 10px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 1px;
  }

  @include list-mode {
    display: none;
  }
}

.cell {
  box-sizing: border-box;
  display: flex;
  padding: $xs $s;
  flex-direction: column;
  justify-content: center;

  &:first-child {
    padding-left: $side-padding;
  }

  &:last-child {
    padding-right: $side-padding;
  }

  > :nth-child(2) {
    margin-top: $s;
  }

  @include list-mode {
    padding: 0;
    margin-bottom: $xs;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    > :nth-child(2) {
      margin-top: $xs;
    }
  }
}

.selectCell {
  box-sizing: border-box;
  max-width: 0;
  overflow: hidden;
  @include transitionify(max-width);

  @include list-mode {
    padding-left: $s !important;
  }

  &:not(.visibleSelectCell) {
    padding: 0;
  }
}

.selectCell label {
  display: inherit;
}

.hideCell {
  display: none;
}

.visibleSelectCell {
  composes: selectCell;
  max-width: 46px;
}

.actions {
  position: absolute;
  display: flex;
  right: 0;
  background-color: white;
  top: $xs;
  height: $action-size;
  max-width: 100vw;

  @include list-mode {
    top: 0;
    height: 100%;
  }
}

.actionItem {
  height: 100%;
  align-self: center;
  margin-left: 1px;
  margin-right: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @include transitionify(background-color);

  &::before {
    content: " ";
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    @include transitionify(background-color);
    background-color: rgba(0, 0, 0, 0);
  }

  &:disabled {
    background-color: $gray-3 !important;
  }

  &:focus {
    outline: 0;
  }

  &:focus::before {
    background-color: rgba(0, 0, 0, 0.15);
  }

  &:hover::before {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.actionLabel {
  margin-top: $xs;
}

.arrowExpander {
  composes: actionItem;
  background-color: $blue-1;
  width: 20px;
  text-align: center;
  box-sizing: border-box;
  padding-left: 5px;
}

.actionsDrawer {
  display: flex;
  overflow: hidden;
  width: 0;
  @include transitionify(width);

  &.dragging {
    transition: none;
  }
}

.drawerOverflow {
  flex: 1;
  background-color: $blue-1;

  // We use the same color for the text and background to prevent
  // the user from seeing a black box if the browser doesn't support the emoji
  color: $blue-1;
  padding-left: 50px * 2;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

//.actionsDrawerExpanded {
//  composes: actionsDrawer;
//}

.action {
  composes: actionItem;
  min-width: $action-size;
}

.expanderWrapperPadding {
  margin-right: $side-padding;
  width: 18px;
}

.expanderWrapper {
  composes: expanderWrapperPadding;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expanderBottom {
  position: absolute;
  left: 50%;
  height: 20px !important;
  bottom: -10px;
}

.expanderIcon {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $expand-color;
  border: 1px solid transparent;
  @include transitionify(all);

  &:focus {
    outline: 0;
    border-color: darken($focus-expand-color, 10%);
    background-color: $focus-expand-color;
  }

  &:hover {
    background-color: darken($expand-color, 20%);
  }

  > :first-child {
    @include transitionify(all);
  }
}

.expandedIcon {
  composes: expanderIcon;
  background-color: $white !important;
  transform: rotate(180deg);

  > :first-child {
    fill: $expand-color;
  }
}

.selectedActions {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  position: sticky;
  bottom: -1px;
  align-items: center;
  composes: _row;
  background-color: $header-color;
  @include safe-bottom(padding, -10px);
  @include transitionify(transform);

  > * {
    margin-right: $s;
  }
}

.visibleSelectedActions {
  composes: selectedActions;
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.selectedAction {
  display: flex;
  align-items: center;
  height: 36px;
  box-sizing: border-box;
  padding: 0 $s;
  border: 0;
  @include transitionify(background-color);

  &:hover {
    background-color: darken($gray-1, 20%);
  }

  &:disabled {
    background-color: $gray-3 !important;
  }

  .icon {
    margin-right: $xs;
  }
}

.rowHeightSmall {
  position: relative;
  ._row {
    min-height: $row-height-small;
  }

  .selectedActions {
    min-height: $row-height;
  }

  .expanderWrapper {
    height: $row-height-small;
  }
}

.rowHeightLarge {
  position: relative;
  ._row:not(.header) {
    min-height: $row-height;
  }

  .expanderWrapper:not(.header) {
    height: $row-height;
  }
}

.emptyMessage {
  flex: 1;
  min-height: 100px;
  border-left: $border-light;
  border-right: $border-light;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderButton {
  cursor: default;
  display: flex;

  > :last-child {
    margin-left: $s;
  }

  &:focus {
    box-shadow: none;

    svg {
      fill: $blue-3;
    }
  }
}

.fixedHeightContent {
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}
