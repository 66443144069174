@import "~styles/variables";

.programPopup {
  background: $gray-1;
  padding: $m;
}

.productsPopup {
  background: $gray-1;
  padding: $m $m $m $xxl;
}

.productsList {
  color: $white;
  list-style: disc;
  font-family: $Lato;

  > li:not(:last-child) {
    margin-bottom: $xs;
  }
}

.productPills {
  display: flex;
  flex-wrap: wrap;
}

.addProducts {
  margin-top: 3px;
}
