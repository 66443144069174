@import "~styles/variables";

.statsBoard {
  display: flex;
}

.loadingStatsBoard {
  @include pulse-animation;
}

.small {
  height: 55px;

  .name {
    font-size: 10px;
  }

  .value {
    font-size: 20px;
  }
}

.medium {
  height: 70px;

  .name {
    font-size: 12px;
  }

  .value {
    font-size: 25px;
  }
}

.large {
  height: 100px;

  .name {
    font-size: 16px;
    margin-bottom: $s;
  }

  .value {
    font-size: 30px;
  }
}

.name {
  text-align: center;
  margin-bottom: $xs !important;
  line-height: normal !important;
  font-family: $WesFY-Bold !important;
}

.value {
  line-height: normal !important;
}

.flip {
  order: 1;
}

.transformVertically {
  transform: scaleY(-1);
  > div {
    transform: scaleY(-1);
  }
}
