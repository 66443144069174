@import "~styles/variables";

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 600px;
  }
}

.contentText {
  line-height: 26px;
  height: 26px;
}

.compactTop {
  composes: top;

  @include mobile {
    justify-content: flex-start;
  }
}

.border {
  border-top: 1px solid white;
  margin-top: $s;
  margin-bottom: $s;
}

.inverseBorder {
  composes: border;
  border-top-color: $gray-1;
}
