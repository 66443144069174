@import "~styles/variables";

.rangeSelect {
  display: flex;
  border-radius: $border-radius;
  background-color: $gray-3;

  > :first-child {
    flex: 1;
  }
}

.sep {
  width: 1px;
  background-color: $gray-2;
}

.selectBtn {
  padding: $m;

  > svg {
    transform: scaleX(-1);
  }
}
