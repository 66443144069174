@import "~styles/variables";

.group {
  display: flex;
}

.group > * {
  border-right: $border-light;
  flex: 1;
}

.group > :last-child {
  border-right: 0;
}
