@import "~styles/variables";

a.link {
  font-family: $Lato;
  font-weight: normal;
  letter-spacing: 0.5px;
  text-decoration: underline;

  &.primary {
    color: $gray-1;
    font-size: 22px;
    line-height: 24px;
  }

  &.secondary {
    color: $gray-1;
    font-size: 18px;
    line-height: 20px;
  }

  &.tertiary {
    font-size: 18px;
    line-height: 20px;
    color: $gray-3;
  }

  &.blue {
    font-size: 18px;
    line-height: 20px;
    color: $blue-2;
  }

  &.blue1 {
    font-size: 14px;
    line-height: 20px;
    color: $blue-1;
    text-decoration: none;

    &:hover {
      color: darken($blue-1, 10%);
    }

    &:active {
      color: darken($blue-1, 30%);
    }

    &:visited {
      color: darken($blue-1, 20%);
    }
  }

  &.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 70px;
    padding: $m;
    box-sizing: border-box;
    line-height: 20px;
    text-decoration: none;
    background-color: $blue-1;
    cursor: pointer;
    position: inherit;
    border-radius: $border-radius;
    border: 0;
    transition: 100ms;
    font-family: $WesFY-Bold;
    font-size: 12px;
    color: $white;
    letter-spacing: 1.5px;
    text-transform: uppercase;

    &:active {
      background-color: darken($blue-1, 20%);
    }

    &:hover,
    &:visited {
      color: $white;
    }
  }

  &:visited {
    color: $blue-1;
  }

  &:hover {
    color: darken($gray-1, 20%);
  }

  &:active {
    color: $pink-1;
  }
}
