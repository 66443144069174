@import "~styles/variables";

.roleOverview {
  flex: 1;
  display: grid;
  grid-template-columns: 35% 65%;
  grid-column-gap: $m;
  grid-row-gap: $m;
  margin-top: -$m;
  padding: 0 $xxl + 5px;
}
