@import "~styles/variables";
@import "../Block/styles";

.cellContent {
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
}

.cr1 {
  white-space: nowrap;
  max-width: unset;
  font-family: $WesFY-Bold;
  line-height: 12px;
  text-overflow: ellipsis;
}

.cr2 {
  line-height: 12px;
}

.compact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .cr1 {
    line-height: 22px;
    font-family: $WesFY-Black;
    white-space: nowrap;
  }

  .cr2 {
    line-height: 22px;
    margin-top: -2px;

    &::before {
      margin: 0 5px;
      font-family: $WesFY-Black;
      color: $black;
      content: " • ";
    }
  }

  justify-content: flex-start;
  padding: 5px 10px;
  height: 30px;
  box-sizing: border-box;

  width: calc(100% - 50px);

  > :first-child {
    min-width: 20px;
  }

  > :nth-child(2) {
    margin: 0 0 0 5px;
  }

  &:not(:last-child) {
    border: none;
  }

  &:first-child {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    height: 35px;
    width: 100%;
    border: none;
    border-radius: 0;
    border-top-left-radius: $block-radius;
    border-top-right-radius: $block-radius;

    @include mobile {
      padding: 0 3px;
    }

    > :nth-child(2) {
      flex: 1;

      &::before {
        color: $white;
      }
    }
    > :last-child {
      &::before {
        content: "";
      }
    }
  }

  &:last-child {
    border: none;
    border-radius: 0;
    border-bottom-left-radius: $block-radius;
  }
}

.detailed {
  margin-left: $m;
  padding: 0;
}

.dark {
  .cr1,
  .cr2,
  .cr3 {
    color: white !important;

    > a {
      color: white !important;
    }
  }
}

.em {
  .cr1 {
    font-size: 18px !important;
    line-height: 20px !important;
  }
}
