@import "~styles/variables";

.button {
  font-family: $Lato;
  font-size: 15px;
  font-weight: bold;
  user-select: none;
}

.newStyle {
  font-family: $WesFY-Bold;
  font-size: 13px;
  letter-spacing: 1.3px;
}

.primary {
  color: $gray-1;
}

.secondary {
  color: $gray-3;
}

.tertiary {
  color: $gray-3;
}

.reverse {
  color: $white;
}

.inherit {
  color: inherit;
}
