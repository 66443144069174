@import "~styles/variables";

.dropzone {
  position: relative;
  flex: 1;
  cursor: pointer;
}

.dropzoneContent {
  pointer-events: none;
  position: relative;
  box-sizing: border-box;
  padding: $xxl;
  border: 2px dashed $gray-3;
  border-radius: 10px;
  @include transitionify(border-color);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropzoneExp {
  @include transitionify(opacity);
}

.dndText {
  margin-bottom: $s;
}

.key {
  position: absolute !important;
  top: $m;
  right: $m;
}

$rotate: 10deg;
$active-rotate: 20deg;

.icons {
  @include transitionify(transform);
  margin-bottom: $m;

  > * {
    @include transitionify(all);
  }

  > :first-child {
    transform: rotate(-$rotate);
    margin-right: $m;
  }

  > :last-child {
    transform: rotate($rotate);
  }
}

.activeDropzone > .dropzoneContent {
  border-color: $teal-1;

  > .icons {
    transform: translate3d(0, -$m, 0);

    > :first-child {
      transform: rotate(-$active-rotate);
      margin-right: $xxl;
    }

    > :last-child {
      transform: rotate($active-rotate);
    }
  }
}

.rejectDropzone > .dropzoneContent {
  border-color: $pink-1;

  .dndText {
    color: $pink-1;
  }

  > .icons {
    svg {
      fill: $pink-1;
    }
  }
}

.activeDropzone:not(.rejectDropzone) {
  > .dropzoneContent > .dropzoneExp {
    opacity: 0;
  }
}
