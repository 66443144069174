@import "~styles/variables";

$ch-btn-size: 35px;

.decimalBox {
  @include card;
  position: relative;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 $m;

  &.containerGlow {
    @include focus-glow;
  }

  &.disabled {
    $darkened-bg: darken($white, 5%);
    background: $darkened-bg;

    > div > input {
      background: $darkened-bg;
    }
  }
}

.errorDecimalBox {
  composes: decimalBox;
  border: 1px solid $pink-1;
}

.input {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: 0;
  border-radius: $border-radius;
  font-family: monospace;
  font-size: 24px;
  text-align: right;
  padding: 0;
  margin: 0;
  align-self: center;
  -moz-appearance: textfield;

  &::placeholder {
    font-family: $Lato;
    margin: 0;
    color: $gray-3;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.moneySymbol {
  color: $gray-1;
  font-family: $Lato;
  padding: 0;
  margin: 0;
}

.label {
  position: absolute;
  z-index: 1;
  left: $form-el-side-padding;
  right: $form-el-side-padding;
  padding-top: $form-el-top-padding;
  padding-bottom: 2px;
  pointer-events: none;
  top: 0;
  width: 130px;
}

.inputWithLabel {
  padding-top: $form-el-top-padding;
}

.optionalLabel {
  display: inline-block;
  font-family: $Lato;
  font-style: italic;
  font-size: 12px;
  color: $gray-3;
  user-select: none;
}

.withLabel {
  position: relative;
}

.negative {
  color: $pink-1;
}
