//background-color: $color;
@import "~styles/variables";

@mixin status-color($color) {
  background-color: $color;

  &:hover,
  &:focus {
    background-color: darken($color, 10%);
  }
}

.draft {
  @include status-color(#4b4974);
}

.upcoming {
  @include status-color(#764e62);
}

.live {
  @include status-color(#c49a3f);
}

.review {
  @include status-color(#526c64);
}

.pending,
.paid {
  @include status-color($gray-1);
}

.invoiced {
  @include status-color($gray-2);
}

.imported {
  @include status-color($gray-3);
}

.trash {
  @include status-color($pink-1);
}

.finalVerified {
  background-color: lighten($gray-1, 50%);
}

//.planning,
//.confirmed,
//.complete,
//.cancelled {
//* {
//color: $white !important;
//}
//}
