@import "~styles/variables";

.size {
  width: 400px;
  box-sizing: border-box;

  @include mobile {
    width: calc(100vw - #{$modal-spacing * 2});
  }
}

.notAssignedSize {
  composes: size;
  min-height: 330px;
}

.loadingSize {
  min-height: 220px;
}

.content {
  flex: 1;
  background-color: $gray-5;
  padding: $l $modal-spacing $modal-spacing $modal-spacing;
}

.proTip {
  color: $pink-1;
  font-family: $WesFY-Black;
  font-weight: 900;
}

.proTipText {
  margin-top: $s;
  margin-bottom: $l;
}

.link {
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.doNotSeeThePerson {
  margin-top: $m;
}

.message {
  margin-top: $l;
}

.statusPill {
  margin: 0 0 0 $s;
}

.pending {
  composes: statusPill;
  background-color: $gray-3;
}

.nameCell {
  display: flex;
  align-items: center;
}

.userName {
  @include ellipsis;
  max-width: 130px;
}

.tableHeader {
  position: unset;
}
