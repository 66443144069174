@import "~styles/variables";

$uploader-width: 100%;
$uploader-max-width: 300px;
$max-image-height: 175px;

.button {
  height: 70px;
  width: $uploader-width;
  max-width: $uploader-max-width;
  border-radius: $border-radius-small;
  background-color: $gray-3;
  border: 0;
  transition: 0.2s;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: $shadow;

  &:hover {
    background-color: darken($gray-3, 10%);
  }

  &:active {
    box-shadow: 0;
    background-color: darken($gray-3, 20%);
  }
}

.attachment {
  margin-top: -1px;
  margin-right: 4px;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: $max-image-height;
  padding: $s;
}

.imageContainer {
  background-size: cover;
  width: $uploader-width;
  height: $max-image-height;
  max-width: $uploader-max-width;
  max-height: $max-image-height;
  overflow: hidden;
  border: $border-darkest;
  border-color: #dddfe5;
  border-radius: $border-radius;
  background-color: $gray-1;
  box-sizing: border-box;
  position: relative;
}

.absoluteImageContainer {
  $adjustment: 2px;
  position: absolute;
  overflow: hidden;
  width: $uploader-max-width - $adjustment;
  height: $max-image-height - $adjustment;
}

.x {
  position: absolute;
  top: 0;
  right: 0;

  & > * {
    cursor: pointer;
    padding: $s;
  }
}

.adjustUp {
  position: inherit;
  margin-top: -12px;
  margin-right: -23px;
}

.loaderContainer {
  display: inline-block;
  width: 100%;
  height: 10px;
  background-color: $gray-3;
  border-radius: $border-radius;
}

.loader {
  height: 100%;
  border-radius: $border-radius;
  background-color: $white;
  transition: 500ms;
}

.imageTitle {
  margin-bottom: $xs;
}

.imageSection {
  margin-bottom: $m;
}

.bottomSpace {
  margin-bottom: $s;
}
