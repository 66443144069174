@import "~styles/variables";
@import "../common.scss";

.dayPicker {
  background-color: $white;
  padding: $m;
}

.navigator {
  display: flex;
  align-items: center;
  margin-bottom: $s;
  justify-content: space-between;
}

.range {
  display: flex;
  align-items: center;
  justify-content: center;

  > :first-child {
    margin-right: $xs;
  }
}

.view {
  display: flex;
  justify-content: center;

  > * {
    width: $calendar-size;
    width: var(--calendar-size);
  }

  > :last-child:not(:first-child) {
    margin-left: $m;
  }
}
