@import "~styles/variables";

.empty {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emptyTitle {
  text-align: center;
  margin-bottom: $s;
}

.emptySubtitle {
  text-align: center;
  margin-bottom: $l;
}

.compactFlex {
  @media (max-width: $tablet) {
    > div {
      flex: 1;
    }
  }
}
