@import "~styles/variables";

@function encode-color($string) {
  @if type-of($string) == "color" {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote("#{$hex}");
  }
  $string: "%23" + $string;
  @return $string;
}

@mixin bg-pattern($color1, $color2) {
  background: linear-gradient(45deg, #{transparentize(#090e16, 0.1)}, transparent),
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{encode-color($color2)}' viewBox='0 0 750 750'%3E%3Crect width='750' height='750' fill='#{encode-color($color1)}'%3E%3C/rect%3E%3Cpath d='M375 375L187.5 187.5H0L187.5 375c0 103.6 83.9 187.5 187.5 187.5V375zM187.5 187.5H375V0C271.4 0 187.5 83.9 187.5 187.5zM375 562.5C375 666.1 458.9 750 562.5 750V562.5H750L562.5 750H750V562.5C750 458.9 666.1 375 562.5 375L375 562.5zM562.5 187.5H750L562.5 0 375 187.5V375c103.6 0 187.5-83.9 187.5-187.5zM187.5 0H0v187.5c103.6 0 187.5-83.9 187.5-187.5z' class='st0'/%3E%3Cpath d='M187.5 750L0 562.5h187.5V375L0 562.5V750h375V562.5c-103.6 0-187.5 83.9-187.5 187.5zM750 187.5L562.5 375H750z' class='st0'/%3E%3C/svg%3E");
  background-size: contain, 200px 200px;
  background-repeat: repeat;
}
