@import "~styles/variables";

.tooltipBody {
  background-color: $gray-1;
  border-radius: 6px;
  color: white;
  display: flex;
  flex-direction: column;
  max-width: 460px;
  padding: $m;
}

.title {
  color: $pink-1;
  font-family: $WesFY-Black;
  font-size: 9px;
  font-weight: 900;
  letter-spacing: 0.9px;
  margin-bottom: $xs;
}

.content {
  font-family: $Lato;
  font-size: 14px;
  font-weight: 100;
  line-height: 1.29;
  margin-bottom: $m;
  > span {
    font-family: $Lato-Bold;
    font-weight: 600;
  }
}
