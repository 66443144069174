@import "~styles/variables";

.markdownWrapper {
  a {
    font-weight: normal;
    color: $blue-2;
    text-decoration: underline;
  }

  ul {
    list-style: disc;

    > li {
      margin-left: 15px;
    }
  }
}
