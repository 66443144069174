@import "~styles/variables";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  height: 25px;
}

$switch-width: 26.5px;
$switch-height: 16px;

.switch {
  display: inline-block;
  height: $switch-height;
  position: relative;
  width: $switch-width;
  margin-right: 10px;

  > input {
    display: none;
  }
}

$slider-padding: 2px;
$slider-size: $switch-height - $slider-padding * 2;

.slider {
  background-color: $gray-3;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;

  &.round {
    border-radius: 34px;
  }

  &.round:before {
    border-radius: 50%;
  }

  &:before {
    background-color: $white;
    content: "";
    left: $slider-padding;
    bottom: $slider-padding;
    position: absolute;
    transition: 0.4s;
    height: $slider-size;
    width: $slider-size;
  }
}

input:checked + .slider {
  background-color: $teal-1;
}

input:checked + .slider:before {
  transform: translateX($slider-size - $slider-padding);
}

.labelText {
  font-size: 14px;
  text-align: left;
  margin-right: $s;
}
