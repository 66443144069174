@import "~styles/variables";

.searchInput {
  color: $white !important;
}

.normal {
  composes: searchInput;
  font-family: $WesFY-Bold;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 0.5px;
}

.compact {
  composes: searchInput;
  font-family: $Lato;
  font-weight: normal;
  font-size: 22px !important;
}

.inverse {
  color: $gray-1 !important;
}
