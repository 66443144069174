@import "~styles/variables";

.card {
  background-color: white;
  display: flex;
  min-height: 120px;
}

.workflowCard {
  flex-wrap: wrap;
}

.mobileCard {
  flex-direction: column;
}

.map {
  width: 120px;
}

.workflowMap {
  order: 1;
}

.address {
  border-right: $border-medium;
  flex: 1;
  padding: $m;
  > :first-child {
    margin-bottom: $s;
  }
  :not(first-child) {
    margin-bottom: $xs;
  }
}

.workflowAddress {
  flex-basis: 100%;
  order: 0;
}

.locationInfo {
  flex: 1;
  padding: $m;
  > :first-child {
    margin-bottom: $s;
  }
  :not(first-child) {
    margin-bottom: $xs;
  }
}

.workflowLocationInfo {
  order: 2;
}
