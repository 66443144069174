@import "~styles/variables";

.tagDescription {
  margin-top: $xxs;
}

.tagColor {
  height: 100%;
  width: 15px;
  position: absolute;
  top: 0;
  right: 0;
}

.saveTagWrapper {
  flex: 0;
  border: 0;
  margin-right: $s;
  display: flex;
  align-items: center;
}

$save-tag-size: 25px;

.saveTag {
  border: 0;
  padding: 0;
  background-color: $teal-1;
  width: $save-tag-size;
  height: $save-tag-size;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2.5px;
  @include transitionify(background-color);

  &:hover,
  &:focus {
    background-color: darken($teal-1, 10%);
    outline: 0;
  }

  &:active {
    outline: 0;
    background-color: darken($teal-1, 20%);
  }
}

.newTagWrapper {
  border-top: $border-light;
}

.newTagTitle {
  flex: 0.5;
}

.newTagDescription {
  margin-right: $s;
  border-right: 0;
}
