@import "~styles/variables";

.cardContainer {
  position: relative;
}

.expenseCard {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  @include focus-glow;
}

.expenseCardError {
  box-shadow: 0 0 2px 1px $pink-1;
}

.expenseImage {
  display: flex;
}

.removeExpense {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -$m;
  right: -$m;
  background-color: $gray-3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: $border-light;
}

.expenseData {
  background-color: white;
  > div {
    box-sizing: border-box;
    display: flex;
    height: 40px;
    justify-content: space-between;
  }
  > :not(first-child) {
    border-top: $border-medium;
  }
}

.expenseItem {
  display: flex;

  :nth-child(2) {
    display: flex;
  }

  input[type="text"] {
    border: none;
    font-family: $Lato;
    font-size: 14px;
    padding-right: $m;
    max-width: 140px;
    text-align: right;
  }

  input[type="text"]:focus {
    text-align: left;
  }
}

.dropdown {
  > select {
    padding-bottom: 11px;
    padding-top: 11px;
  }
  > span {
    top: calc(50% - 10px);
  }
}

.itemText,
.errorText {
  padding: 15px;
}

.basicMoney {
  height: unset;
  width: unset;
  box-shadow: unset;
  div,
  input {
    font-size: 14px;
  }
}
