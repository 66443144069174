@import "~styles/variables";

$color: $blue-2;

$size: 20px;

.container {
  height: $size;
  width: $size;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: $color;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transitionify(all);

  &:focus {
    outline: 0;
    border-radius: 50%;
    border-color: darken($color, 30%);
    background-color: darken($color, 20%);
  }

  &:hover {
    background-color: darken($color, 10%);
  }

  > svg {
    @include transitionify(all);
  }
}

.back {
  composes: container;

  > svg {
    transform: rotate(90deg);
  }
}

.forward {
  composes: container;

  > svg {
    transform: rotate(-90deg);
  }
}

.up {
  composes: container;

  > svg {
    transform: rotate(-180deg);
  }
}

.down {
  composes: container;
}
