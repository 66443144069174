@import "~styles/variables";

.wrapper {
  position: relative;
  box-sizing: border-box;
  --calendar-size: 250px;
}

.focused {
  @include focus-glow;
  height: 67px;
}

.box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.dropdown {
  position: absolute;
  z-index: 10;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  box-shadow: $shadow;
  border-top: 1px solid $gray-5;
  background-color: $white;
  border-radius: $border-radius;
}
