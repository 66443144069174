@import "~styles/variables.scss";

.importerSectionField {
  border-bottom: 1px solid white;
  padding: $xs 0;
}

.importerField {
  margin-bottom: $m;
}

.helpText {
  color: $gray-1;
  margin-bottom: $s;
}

.label,
.headerLabel {
  position: sticky;
  top: 0;
  z-index: 1;
  width: calc(100% + 5px);
  background-color: $gray-1;
  padding: $m 0 $xs 0;
}

.headerLabel {
  padding: 0;
}

.sources {
  margin: 0;
  height: auto;
  transition: all 0.3s ease-in-out;
}

.showSources {
  visibility: visible;
  opacity: 1;
}

.sourceWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.removeSource {
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: $gray-1;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  z-index: 2;

  &:focus {
    border-radius: 50%;
  }
}

.addSource {
  margin-top: $s;
  align-self: flex-start;
  display: inline-block;
  > * {
    display: inline-block;
  }
}

$item-size: 20px;

.joinBtn {
  margin-top: $s;
  margin-bottom: $s;
  background-color: $blue-2;
  border-radius: 50%;
  max-width: $item-size;
  max-height: $item-size;
  overflow: hidden;
  @include transitionify(all);

  &:hover {
    border-radius: 10px;
    max-width: $item-size * 2;
  }

  &:focus:not(:hover) {
    border-radius: 50%;
  }
}

.joinBtn:hover > .joinOptions {
  transform: translateX(0);

  > * {
    background-color: $white;
  }
}

.joinOptions {
  display: flex;
  align-items: center;
  height: $item-size;
  width: $item-size * 2;
  @include transitionify(transform);

  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $item-size;
    height: $item-size;
    box-sizing: border-box;
    @include transitionify(background-color);

    &:hover {
      background-color: $blue-2 !important;
    }
  }
}

.joinOptionsPlus {
  composes: joinOptions;
}

.joinOptionsOr {
  composes: joinOptions;
  transform: translateX(-#{$item-size});
}

.importerFieldHeader {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: initial;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}
