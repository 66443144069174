@import "~styles/variables";

.folding-cube {
  margin: 28px auto;
  width: 50px;
  height: 50px;
  position: relative;
}

.blueCube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $gray-1;
    animation: foldCubeAngle 1.8s infinite linear both;
    transform-origin: 100% 100%;
  }
}

.whiteCube {
  composes: blueCube;

  &:before {
    background-color: $white;
  }
}

.folding-cube .cube2 {
  transform: scale(1.1) rotateZ(90deg);
}

.folding-cube .cube3 {
  transform: scale(1.1) rotateZ(180deg);
}

.folding-cube .cube4 {
  transform: scale(1.1) rotateZ(270deg);
}

.folding-cube .cube2:before {
  animation-delay: 0.2s;
}

.folding-cube .cube3:before {
  animation-delay: 0.4s;
}

.folding-cube .cube4:before {
  animation-delay: 0.6s;
}

@keyframes foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
