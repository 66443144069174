@import "~styles/variables";

$st-border-radius: 10px;

.siblingBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $s;
  box-sizing: border-box;

  &:not(:last-child) {
    margin-right: 1px;
  }

  &:first-child {
    border-top-left-radius: $st-border-radius;
    border-bottom-left-radius: $st-border-radius;
  }

  &:last-child {
    border-top-right-radius: $st-border-radius;
    border-bottom-right-radius: $st-border-radius;
  }
}
