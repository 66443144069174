@import "~styles/variables";

.assign {
  padding-bottom: 10px;
  border-bottom: 4px solid $teal-1;
}

.tabs {
  display: flex;
  padding-top: 8px;
  padding-bottom: 20px;
}

.sharing {
  display: flex;
  position: relative;
}

.sharingLabel {
  position: absolute;
  top: -30px;
  left: 0;
}

.openSendTo {
  white-space: nowrap;
  padding-bottom: 10px;
  border-bottom: 4px solid transparent;

  &:focus,
  &:hover {
    border-color: $blue-2;
  }
}

.disabledOpenTo {
  &:focus,
  &:hover {
    border-color: $gray-4;
  }
}

.unassignSubmitBtn button {
  background-image: unset;
  background-color: $pink-1;

  &:hover {
    background-color: $pink-1;
    filter: brightness(1.1);
  }

  &:active {
    background-color: $pink-1;
    filter: brightness(0.9);
  }
}
