@import "~styles/variables";

.statusBar {
  position: fixed;
  bottom: 0;
  z-index: 1;
  box-sizing: border-box;
}

.savingStatusBar {
  composes: statusBar;
  height: 43px;
  width: 50px;
  margin-left: 5xpx;

  &.helperMargins {
    margin-left: 32px;
  }
}

.textStatusBar {
  composes: statusBar;
  background-color: $gray-5;
  padding: $s $s $s 0;
  border-top-right-radius: $border-radius;
  display: inline-block;
  margin-left: -1px;

  &.helperMargins {
    margin-left: 50px;
  }
}

.error {
  color: $pink-1;
}
