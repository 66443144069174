@import "~styles/variables";

// base

.h {
  font-family: $Lato-Heavy;
  color: $gray-1;
  margin: 0;
  font-weight: normal;
  transition: $transition-duration color $base-timing;
}

// variants

.v1 {
  font-size: 24px;
  line-height: 22px;
}

.v2 {
  font-size: 18px;
  line-height: 22px;
}

.v3 {
  font-size: 14px;
  line-height: 18px;
}

.v4 {
  font-size: 12px;
  line-height: 14px;
}

// kinds

.normal {
  color: auto;
}

.secondary {
  color: $gray-3;
}

.tertiary {
  color: $gray-3;
}

.active {
  color: $blue-1;
}

.error {
  color: $pink-1;
}

.reverse {
  color: white;
}

// etc

.inline {
  display: inline;
}
