@import "~styles/variables";

.container {
  position: relative;
  z-index: 1;

  width: 303px;

  @include mobile {
    width: auto;
    flex: 1;
  }
}

.overlay {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 10;
  background-image: linear-gradient(45deg, $gray-3, $gray-2, $gray-1);
  border-radius: 2px;
  box-shadow: 0px 2.5px 2.5px 0 rgba(0, 0, 0, 0.3);
}

.actions {
  display: flex;
  flex-direction: column;
  padding: $l $l $s;
}

.action {
  font-size: 16px;
  //font-family: $WesFY-Black;
}

.action + .action {
  margin-top: $m;
}

$btn-padding: 12px 0 14px 0;

.status {
  text-align: center;
  padding: $btn-padding;
}

.saveButton {
  padding: $btn-padding;
  box-sizing: border-box;
  font-size: 24px;
  width: 100%;
}

.errorMessage {
  padding: $m;
}

.networkError,
.errorMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 230px;
  box-sizing: border-box;
}

.wifiIcon {
  margin-bottom: $s;
  @include transitionify(fill);
}

.offlineIcon {
  composes: wifiIcon;
}

.offlineIcon > :nth-child(1) {
  animation: top 1.5s ease infinite alternate;
}

@keyframes top {
  0%,
  55% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.offlineIcon > :nth-child(2) {
  animation: medium 1.5s ease infinite alternate;
}

@keyframes medium {
  0%,
  33% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.onlineIcon {
  composes: wifiIcon;
}

.onlineIcon {
  animation: on 2s ease;
}

@keyframes on {
  0% {
    opacity: 0;
  }
  80%,
  100% {
    opacity: 1;
  }
}

.discardIcon {
  align-self: flex-end;
  margin-bottom: 10px;
}
