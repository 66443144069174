@import "~styles/variables";

.clientList {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
  border-top: 1px solid $gray-3;
  padding-top: 16px;
}

.partnerName {
  color: $blue-2;
}

.partnerActivity {
  display: flex;
  align-items: center;
  background-color: #eaecf4;
  margin-top: 16px;
  padding-left: 10px;
}

.roleName {
  color: $teal-1;
}

.description {
  font-family: $WesFY-Regular;
}
