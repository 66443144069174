@import "~styles/variables";

.detailsExpanded {
  flex: 1;
  margin-top: -$m;
  padding: 0 $xxl + 5px;

  > :first-child {
    margin-bottom: $m;
  }
}

.multiLine {
  white-space: pre-wrap;
}
