@import "~styles/variables";

.container {
  display: flex;
  align-items: center;
}

.container:focus {
  outline: 0;
}

.checkbox,
.checkboxSvg {
  height: 16px;
  width: 16px;
}

.checkboxLarge,
.checkboxSvgLarge {
  height: 20px;
  width: 20px;
}
.bg,
.inversedBg {
  @include transitionify(all);
  stroke: $gray-3;
  fill: $white;
}

.checkedBg {
  composes: bg;
  stroke: $blue-1;
  fill: $blue-1;
}

.inversedCheckedBg {
  composes: bg;
  stroke: $teal-1;
  fill: $teal-1;
}

.tick {
  @include transitionify(transform);
  transform-origin: 160% 0;
}

.checkedTick {
  composes: tick;
  transform-origin: 0 80%;
}

.inversedTick {
  composes: checkedTick;
  fill: $white;
}

$focused-checked-color: darken($blue-1, 10%);

.enabledContainer {
  composes: container;
}

.enabledContainer:focus {
  .bg {
    stroke: $blue-1;
    fill: transparentize($blue-1, 0.6);

    &.checkedBg {
      stroke: $focused-checked-color;
      fill: $focused-checked-color;
    }

    &.inversedCheckedBg {
      stroke: $teal-1;
      fill: $teal-1;
    }
  }
  .inversedBg {
    stroke: $teal-1;
    fill: transparentize($teal-1, 0.6);
  }
}

.line {
  stroke: $white;
  stroke-width: 3;
}
