@import "~styles/reset";
@import "~styles/fonts";
@import "~styles/variables";

:root {
  --vh-offset: 0px;
}

:global {
  a {
    color: #26334f;
    font-weight: bold;
    text-decoration: none;
  }

  html {
    @include safe-top(margin);
    overflow-y: visible;
  }

  body {
    overflow-y: auto;
    background-color: $gray-5;
  }

  body:after {
    content: " ";
    background-color: #625fa4;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    @include safe-top(height);
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
  }

  .geosuggest {
    flex: 1;
    margin-right: 8px;
  }

  .geosuggest__suggests-wrapper {
    position: relative;
  }

  .geosuggest__suggests {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    right: 0;
    max-height: 200px;
    padding: 0;
    margin-top: -1px;
    background: $white;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    transition: max-height $transition-duration, border $transition-duration;

    @include card;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  .geosuggest__item {
    font-family: $Lato;
    padding: $m;
    color: $gray-1;
    font-size: 15px;
    border-bottom: $border-light;
  }

  .geosuggest__item--active {
    background: $gray-5;
  }

  [data-tooltip] {
    position: relative;
  }

  [data-tooltip]:after {
    content: attr(data-tooltip);
    background: rgba(37, 47, 64, 0.9);
    border-radius: $border-radius;
    color: $white;
    padding: $xs $s;
    position: absolute;
    left: 0;
    opacity: 0;
    top: calc(100% + #{$s});
    font-size: 13px;
    white-space: pre;
    font-family: $Lato;
    transform: translate3d(0, 40%, 0);
    transition: $transition-duration all $base-timing 600ms;
    box-shadow: $shadow;
    z-index: 99999;
    text-transform: none;
    pointer-events: none;
  }

  [data-tooltip-right]:after {
    right: 0;
    left: unset;
  }

  [data-tooltip]:hover:after {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .flex {
    display: flex;
  }

  .spacer {
    flex: 1;
    -ms-flex: 1;
  }

  .emoji {
    font-family: "Segoe UI Emoji";
  }

  input::placeholder {
    color: $gray-3 !important;
  }
}
