@import "~styles/variables";

.fd {
  position: fixed;
  z-index: 5;
  @include transitionify(bottom);
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}
