@import "~styles/variables";

.source {
  background-color: $gray-2;
  width: 100%;
  border-radius: $border-radius;
}

.sourceOptions {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: $m;
}

.sourceOption {
  min-width: 72px;
  padding: 8px;
  border-radius: 20px;
  background-color: transparent;
  text-align: center;
  @include transitionify(background-color);

  &:focus {
    border-radius: 20px;
    box-shadow: none;
    background-color: lighten($gray-3, 20%);
  }

  & + & {
    margin-left: $m;
  }
}

.sourceOptionActive {
  composes: sourceOption;
  background-color: $gray-3;
}
