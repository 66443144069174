@import "~styles/variables";

.toggleSection {
  margin-top: 10px;
  margin-bottom: -10px;
}

.toggleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  padding: $s;
}

.toggleLabel {
  align-items: center;
  display: flex;
  font-family: $Lato;
  font-size: 18px;
}

.gigApprovalBody {
  display: flex;
  justify-content: left;
  width: 100%;
  margin-bottom: $xs;
  align-items: center;
}

.gigApprovalNote {
  margin-left: $s;
}

.toggle {
  border-radius: unset;
  padding-right: $s;
}

.toggleAll {
  color: $white;
}

.usersSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;

  > :first-child {
    font-size: 16px;
    color: $gray-1;
  }
}

.note {
  font-style: italic;
  margin: 5px 0;
}
