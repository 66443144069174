@import "~styles/variables";

.billing {
  display: flex;
  background: white;
  border-top: 1px solid $gray-4;
  flex-direction: column;
  width: 100%;
}

.talentAndExpenses {
  flex: 1;
  padding: $xxl;
  max-width: 700px;
  overflow-y: auto;
  background-color: $gray-5;
}

.expenseMemoContainer {
  padding-top: $l;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: $l;
}

.expenseMemo {
  background: white;
}

.paymentTitle {
  padding-bottom: 20px;
  border-bottom: 1px solid $gray-4;
}

.sectionTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.expensesSectionTitle {
  composes: sectionTitle;
  padding-bottom: 0;
}

.tooltip {
  background: $gray-1;
  padding: $s;
  border-radius: $m;
}

.invoiceAndMessages {
  box-shadow: -1px 0 7px 1px #0000000f;
  z-index: 1;
  background-color: lighten($gray-5, 2%);
  display: flex;
  flex-direction: column;
  padding: $xxl;

  > div:first-child {
    margin-bottom: $l;
  }
}

.gigCharges {
  display: flex;
  padding-bottom: 10px;
  max-width: 300px;
  justify-content: space-between;
}

.chargesDecimal {
  font-size: 18px;
}

.notificationSwitch {
  display: flex;
  align-items: center;
  > div:first-child {
    margin-right: $s;
  }
}

.fieldShadow {
  box-shadow: $shadow;
}

.userPicture {
  width: 160px !important;
  height: 160px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
}

.talentContainer {
  display: flex;
  margin-bottom: $m;
  @include card;

  @include mobile {
    flex-direction: column;
  }
}

.talentLink {
  @include mobile {
    display: flex;
    justify-content: center;
    background-color: lighten($gray-4, 20%);
  }
}

.rateAction {
  display: flex;
  flex: 1;
  box-shadow: 0px 0px 2px 2px #c2e4fd;
  border-radius: 2px;

  @include mobile {
    flex-direction: column;
  }
}

.rateContainer {
  display: flex;
  flex-direction: column;
  > :not(:last-child) {
    border-bottom: $border-light;
  }
  flex: 1;
}

.rates {
  display: flex;
  flex: 1;
  padding: $s;
  align-items: center;
  justify-content: space-between;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.editableTalentRate {
  > :last-child {
    flex: 0.3;
  }
}

.expenseTotals {
  display: flex;
  justify-content: space-between;
  margin-top: $xxl;
  align-items: center;

  @include mobile {
    flex-direction: column;
  }
}

.sumTotals {
  display: flex;
  > :last-child {
    font-weight: bold;
    margin-left: $s;
  }
}

.gigTotalToBeCharged {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 5px 0 10px 0;

  @include mobile {
    align-self: flex-start;
  }
}

.trackingBlockWrapper {
  @include card;
  overflow: hidden;
  margin: 20px 0;
}

.addExpense {
  border-color: $blue-2;
  background-color: $blue-2;
  color: white;
  margin-right: unset;
  margin-bottom: unset;

  @include mobile {
    align-self: flex-start;
  }
}

.disabled {
  background-color: $gray-2;
  &:hover {
    background-color: $gray-2;
  }
}

.addExpenseItems {
  display: flex;
  align-items: center;
  > svg {
    margin-right: $s;
  }
}

.actionContainer {
  flex: 0.15;
  display: flex;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.authorizeBtn {
  box-shadow: unset !important;
  padding: unset;
  width: 100%;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  border-top-left-radius: unset;

  @include mobile {
    min-height: 50px;
  }
}

@import "~styles/variables";

.expenseCard {
  @include card;
  display: flex;
  flex-direction: row;
  height: 160px;
  margin-top: $xxl;
  > div {
    margin: unset;
  }
  @include mobile {
    flex-direction: column;
    height: unset;
  }
}

.expensePicture {
  flex: 0.25;
  background: $blue-2;
  width: 120px;
}

.expenseInfo {
  box-sizing: border-box;
  width: 120px;
  background: darken($white, 2%);
  padding: $s;
  > div:not(:last-child) {
    margin-bottom: $xs;
  }
  @include mobile {
    width: 100%;
  }
}

.itemsAndActions {
  display: flex;
  box-shadow: 0px 0px 2px 2px lighten($blue-2, 20);
  border-radius: $xxs;
}

.expenseItems {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-top-left-radius: $xxs;
  border-bottom-left-radius: $xxs;

  > div:not(:last-child) {
    border-bottom: $border-light;
  }
  > :last-child {
    > :last-child {
      flex: 0.7 !important;
    }
  }
}

.approvedExpenses {
  background: $teal-1;
}

.expenseItem {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding-left: $m;
  > :first-child {
    width: 96px;
  }

  input[type="text"] {
    border: none;
    font-family: $Lato;
    font-size: 14px;
    padding-right: $m;
    max-width: 140px;
    text-align: right;
  }

  input[type="text"]:focus {
    text-align: left;
  }
}

.expenseActions {
  flex: 0.2;
  display: flex;
  flex-direction: column;
  border-top-right-radius: $xxs;
  border-bottom-right-radius: $xxs;
  :first-child {
    flex-basis: 80%;
  }
}

.classify {
  flex: 1;
  padding-left: 10px;
}

.classify > div > div {
  min-height: unset;
  display: flex;
  align-items: center;
  width: 100%;

  select {
    font-size: 14px;
    padding: 8px;
  }
}

.basicMoney {
  height: unset;
  width: unset;
  box-shadow: unset;
  padding: unset;
  padding-right: $m;
  padding-left: $m;
  :first-child {
    font-size: 14px;
  }
}

.authorizeBtn {
  box-shadow: unset !important;
  padding: unset;
  width: 100%;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  border-top-left-radius: unset;
}

.tooltip {
  background: $gray-1;
  padding: $s;
  border-radius: $m;
}

.extraActions {
  background: $blue-1;
  flex: 1;
  border-bottom-right-radius: $xxs;
}

.addToInvoice {
  margin-top: $s;
  width: 100%;
}

.totalHours {
  border: none;
  text-align: right;
  margin: 0;
  font-family: monospace;
  font-size: 14px;
}

.noInvoicing {
  margin-top: 40px;
}

.memo {
  height: 48%;
  white-space: unset;
}

.removeExpense {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -$l;
  right: -$l;
  background-color: $gray-3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: $border-light;
}
