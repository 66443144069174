@import "~styles/variables";

.message {
  font-family: $Lato;
  font-size: 13px;
  line-height: 18px;
  margin: 8px 0 0 0;

  a {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
}

.neutral {
  color: $blue-2;
}

.success {
  color: $teal-1;
}

.error {
  color: $pink-1;
}

.info {
  color: $blue-1;
}

.inline {
  display: inline;
}

.why {
  margin-left: $xs;
  composes: message;
  color: $blue-1;
  text-decoration: underline;
}

.menu {
  margin-top: $s;
  left: 0;
}

.messageAndExplanation {
  p {
    display: inline-block;
    cursor: pointer;
  }
}

.explanation {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: $border-radius;
  padding: $xs;

  > * {
    font-size: 12px;
    line-height: 16px;
  }
}
