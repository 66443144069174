@import "~styles/variables";

// base

.display {
  font-family: $WesFY-Black;
  font-size: 42px;
  font-weight: 400;
  color: $gray-1;
  line-height: 40px;
  @include transitionify(color);
}

// variants

.v1 {
  font-size: 42px;
}

.v2 {
  font-size: 28px;
  line-height: 28px;
}

.v3 {
  font-size: 24px;
  line-height: 24px;
}

.v4 {
  font-size: 18px;
  line-height: 20px;
  font-family: $WesFY-Bold;
  font-weight: 900;
}

.v5 {
  font-size: 14px;
  line-height: 16px;
  line-height: 1;
}
// kinds

.primary {
  color: auto;
}

.green {
  color: $teal-1;
}

.highlight {
  color: $pink-1;
}

.inline {
  display: inline-block;
}

.reverse {
  color: white;
}

.secondary {
  font-weight: normal;
  line-height: 1;
  color: $gray-3;
}

.tertiary {
  color: $gray-3;
}
