@import "~styles/variables";

.item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: $l $m $l $m;
  //  TODO add back for after element @include transitionify(background-color);
  border-bottom: 1px solid $gray-3;
}

.greenItem {
  composes: item;
  background-color: $teal-1;
}

.subtitle {
  margin-top: $xxs;
  font-size: 12px;
}

.progress {
  margin-right: $s;
}

.invisible {
  opacity: 0;
}

.arrowBtn {
  margin-left: $m;
}

.whiteArrow {
  $color: $white;
  background-color: $color;

  > svg {
    fill: $teal-1;
  }

  &:focus {
    border-color: darken($color, 30%);
    background-color: darken($color, 20%);
  }

  &:hover {
    background-color: darken($color, 10%);
  }
}

.whiteArrowRedStroke {
  composes: whiteArrow;
  > svg {
    fill: $pink-1;
  }
}

.redBg {
  background-color: $pink-1;
}

.activeBg {
  background-color: $gray-1;
  div {
    color: white;
  }
}

.arrow {
  content: "";
  position: absolute;
  right: -20px;
  top: 0;
  width: 0;
  height: 0;
  // border-top: 37px solid transparent;
  // border-bottom: 37px solid transparent;
  clear: both;
}

.active .arrow {
  border-left: 20px solid $gray-1;
}
