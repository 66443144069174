@import "~styles/variables";

.button {
  border: 2px solid transparent;
  border-radius: 2.5px;
  padding: 13px 18.5px;
  box-sizing: border-box;
  font-family: $WesFY-Bold;
  font-size: 10px;
  letter-spacing: 1.2px;
  transition: $transition-duration all $base-timing;
  user-select: none;
  @include focus;
}

.noPadding {
  padding: 0;
}

.button:focus:hover {
  outline: 0;
}

.primary {
  border-color: $blue-2;
  background-color: $blue-2;
  color: white;

  &:hover {
    background-color: darken($blue-2, 10%);
  }

  &:active {
    background-color: darken($blue-2, 20%);
  }

  &:disabled {
    background-color: $gray-2;
    color: rgba(255, 255, 255, 0.4);
  }
}

.gray {
  border-color: $gray-3;
  background-color: $gray-3;
  color: $white;

  &:hover {
    background-color: darken($gray-3, 10%);
  }

  &:active {
    background-color: darken($gray-3, 20%);
  }
}

.secondary {
  background-color: transparent;
  border-color: transparent;
  color: $gray-3;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
}

.secondary:disabled {
  opacity: 0.7;
}

.section {
  color: white;
  width: 100%;
  padding: 20px $m;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin-bottom: 0;
}

.translucent {
  background-color: transparent;
  color: $blue-3;
  border-color: $blue-3;

  &:hover:not(:disabled) {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:active {
    background-color: white;
    color: $gray-1;
  }

  &:disabled {
    border-color: $gray-4;
    color: $gray-4;
  }
}

.blueTranslucent {
  composes: translucent;
  border-color: $blue-2;
  color: $blue-2;
}

.primaryBold {
  composes: primary;
  border-radius: 0;
  font-family: $WesFY-Black;
  letter-spacing: 1px;
  font-size: 14px;
  height: 45px;
}

.primaryGradient {
  composes: primary;
  background-image: linear-gradient(to right, #6088e5 0%, #60b7f9 50%, #6088e5 100%);
  background-size: 200% auto;
  min-height: 30px;
  box-shadow: 0 1px 1px 0 #272f3f6b !important;
  border: unset;
  &:hover {
    background-position: right center;
  }

  &:disabled {
    background: $gray-4;
    color: $white;
    box-shadow: none !important;
  }
}

.redGradient {
  composes: primaryGradient;
  background-image: linear-gradient(to right, #ae003e 0%, $pink-1 50%, #ae003e 100%);
}

.greenGradient {
  composes: primaryGradient;
  box-shadow: 0px 2.5px 2.5px 0 rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(to right, #46b99c 0%, $teal-1 50%, #46b99c 100%);
  background-size: 200% auto;
  &:hover {
    background-position: right center;
  }
}
