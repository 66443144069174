@import "~styles/variables";
@import "../../../dashboard";

.overview {
  @include max-page-width;
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  padding: $side-padding;
}

.status {
  font-size: 40px;
  //margin-bottom: $xl;
  //border-bottom: $border-dark;
}

.statusActive {
  color: $green;
}

.statusInactive {
  color: $gray-2;
}

.sectionTitle {
  color: $teal-1;
  margin-top: $xl;
  margin-bottom: $l;
}

.content {
  display: flex;
  border-top: 1px solid $gray-3;
}

.leftSide {
  padding-right: $xl;
  flex: 1;
}

.dateRange {
  margin-bottom: $m;
}

.fullStatsBtn {
  width: 100%;
  margin-top: $l;
  color: $blue-2;
  border-color: $blue-2;
  font-size: 14px;
  font-weight: 900;
  margin-bottom: $s;
}

.selectOnlyOne {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > * {
    text-align: center;
  }
}

.selectOnlyOneMessage {
  margin-top: $s;
  margin-bottom: $xxl;
}

.accLayout {
  padding: 0;
}

.sectionLoading {
  margin: 0;
  height: 300px;
}

.section {
  padding-bottom: $m;
}

.edit {
  display: inline-block;
  background-color: $blue-2;
  border-radius: 50%;
  width: 24px;
  text-align: center;
  @include transitionify(box-shadow);

  &:focus {
    @include focus-glow;
    border-radius: 50%;
  }
}

.lock[data-tooltip]::after {
  top: calc(100% + #{$xxs});
}

.sideBar {
  flex: 0.42;
  background: $side-bar-gradient-right;
}

.sideBarContent {
  padding: $xl;
  position: sticky;
  z-index: 2;
  top: 80px;
}

.myPartner {
  margin-bottom: $xl;
}

.myPartnerTitle {
  margin-bottom: $xs;
}

.managersTitle {
  margin-top: $xl;
}

.myPartnerManagers {
  margin-top: $xs;
}

.programManager {
  margin-bottom: $xs;
}

.needHelp {
  margin-bottom: $xs;
}

.overviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: $xxl;
}

.archiveProgramButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $xs;
}
