@import "~styles/variables";

.label,
.label > * {
  font-family: $WesFY-Bold;
  font-size: 12px;
  color: $gray-1;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  cursor: inherit;
}

.tertiary {
  color: $gray-3;
}

.inverse {
  color: $white;
}
