@import "~styles/variables";

.modalFooter {
  background-color: $gray-1;
  text-align: center;
  // position: sticky;
  // bottom: 0;
  // left: 0;
}

.noActionFooter {
  composes: modalFooter;
  padding: $s;
}

.padding {
  padding: 0 $modal-spacing;

  @include mobile {
    padding: 0 $m;
  }
}

.action {
  width: 100%;
  border-radius: 0;
  font-family: $WesFY-Black;
  letter-spacing: 2.5px;
  font-size: 14px;
  height: 65px;
}

.actionPadding {
  composes: padding;
  padding-top: $modal-spacing;
  font-size: 12px;
}

.actionPadding > .action {
  height: auto;
}

.cancel {
  margin: $modal-spacing 0;
  padding: 0;
}

.cancelLabel {
  color: $gray-3 !important;
  font-size: 10px !important;
}

.messageWrapper {
  text-align: left;
  max-height: 0;
  margin-top: 0;
  overflow: hidden;
  @include transitionify(all);
}

.visibleMessageWrapper {
  composes: messageWrapper;
  margin-top: $m;
  max-height: 26px;
}

.otherOptions {
  margin: 17px 0;
  display: flex;

  &Line {
    background-color: $gray-3;
    height: 1px;
    width: 100%;
    flex: 1;
    margin-top: 3px;
  }

  &Text {
    white-space: nowrap;
    font-family: $WesFY-Bold;
    font-size: 8px;
    font-weight: 900;
    letter-spacing: 0.8px;
    margin: 0 $xs;
    color: $gray-3;
  }
}

.bottomActions {
  display: flex;
  margin-bottom: $modal-spacing;
}

.cancelAlt {
  border-color: $gray-3;
  margin-right: $m;
}

.secondaryAction {
  flex: 1;
}
