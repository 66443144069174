@import "~styles/variables";

.modal {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background-color: $gray-5;

  @include tablet-desktop {
    max-height: 100%;
  }
}

.form {
  padding: $modal-spacing;
  flex: 1;
  flex-basis: auto;
}

.dropZone {
  padding: $m $m 0 $m;
}

.linkOption {
  text-align: center;
  padding: $m;

  > span {
    color: $blue-2;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.section > div {
  @include transitionify(height);
  height: 246px;
  box-sizing: border-box;
  overflow: hidden;

  > * {
    border-bottom-width: 0;
  }

  > :first-child {
    flex: 1;
  }
}

.linkSection {
  composes: section;

  > div {
    height: 146px;
    padding-top: 2px;

    > * {
      border-bottom-width: 1px;
    }
  }
}

.uploadingFile {
  box-sizing: border-box;
}

.uploaded {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: $m;
}

.uploadedMessage,
.filePill {
  opacity: 0;
  animation: fileEnter 400ms ease;
  animation-fill-mode: forwards;
}

.uploadedMessage {
  margin: $m 0 $modal-spacing 0;
  animation-delay: 1s;
}

.filePill {
  display: inline-block;
  animation-delay: 1.4s;
}

@keyframes fileEnter {
  0% {
    opacity: 0;
    transform: translate3d(0, -40%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.checkIcon {
  opacity: 0;
  animation: checkEnter 400ms ease;
  animation-delay: 200ms;
  animation-fill-mode: forwards;
}

@keyframes checkEnter {
  0% {
    opacity: 0.5;
    transform: scale(0);
  }

  85% {
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
