@import "~styles/variables";
@import "../styles";

.stepForm {
  position: relative;
  background-color: $gray-5;
  display: flex;
  flex-direction: column;
  padding: $l 0 $xxl 0;
}

.noBottomPadding {
  padding: $l 0 0 0;
}

.question {
  display: flex;
  padding-right: $l;
}

.question:not(:last-child) > .questionField > .questionDiv {
  margin-top: $l;
  margin-bottom: $l;
  display: block;
  height: 1px;
  width: 93px;
  background: linear-gradient(to right, $gray-3 0%, $gray-3 25%, transparent 100%);
}

.questionTitle {
  font-family: $Lato-Bold;
}

.questionStatusIndicator {
  flex-shrink: 0;
  margin-right: $s;
  @include transitionify(color);

  > circle {
    @include transitionify(fill);
  }
}

.questionField {
  flex: 1;
}

.imagesNa {
  margin-left: -$xs;
  border: 1px solid $gray-3;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include transitionify(background-color);
}

.imagesNaActive {
  composes: imagesNa;
  background-color: $gray-1;
}

.imageNaText {
  margin-top: $xs;
  font-family: $WesFY-Black;
  font-size: 10px;
}

.notApplicable {
  margin-top: -$xs;
  margin-bottom: $m;
}

.formFooter {
  position: fixed;
  z-index: 2;
  bottom: 56px;
  left: 0;
  right: 0;
  padding: $s;
  background-color: $gray-1;
  text-align: center;
}

.saveAndReturn {
  width: 100%;
  border-radius: 0;
  font-family: $WesFY-Black;
  letter-spacing: normal;
  font-size: 18px;
  height: 65px;
  margin-bottom: $s;
}

.remember {
  font-family: $WesFY-Bold;
}

.expensesContainer {
  padding: 24px 0 0 24px;
}

.toggleContainer {
  display: flex;
  margin-bottom: $m;

  &:first-child {
    margin-top: -$l;
  }
}

.toggle {
  border-radius: unset;
  height: 46px;
  background: $gray-1;
  padding-right: $s;
}

.toggleLabel {
  align-items: center;
  display: flex;
  padding-left: $l;
  width: 100%;
}

.toggled {
  background-color: $teal-1;
}

.untoggled {
  background: $pink-1;
}

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(39, 47, 63, 0.2);
  z-index: 1;
}

.geosuggest {
  display: block;
  margin-right: 0 !important;

  :global(.geosuggest__input-wrapper) {
    height: 78px;
    > input {
      background: $white;
    }
  }
}

.expenseCard {
  margin-bottom: $m;
}

.temperatureUnit > select {
  width: 80px;
  height: 60px;
  font-family: monospace;
  font-size: 24px;
}

.multipleChoiceManyLabel {
  > :last-child {
    white-space: normal;
  }
}

.customInput {
  padding: 18px 19px 16px;

  &:focus {
    box-shadow: none;
  }
}

.searchableWrapper {
  background: white;
}

.footerContainer {
  position: relative;
  z-index: 2;
  padding: 14px;
  background-color: $gray-1;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.footerButtons {
  display: flex;
  height: 56px;
  box-sizing: content-box;
  gap: 10px;
}

.footerButton {
  width: 100%;
  flex: 1;
  border-radius: 0;
  font-family: $WesFY-Black;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 18px;
  text-transform: uppercase;
}

.footerButtonSecondary {
  font-size: 12px;
}

.atomChoiceContainer {
  display: flex;
  margin-top: $s;
}

.atomChoiceOther {
  margin-left: $s;
  font-size: 14px;
}

.atomChoiceOtherInput {
  margin-top: $s;
  min-height: 100px;
  background: $white;
}
