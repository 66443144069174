@import "~styles/variables";

.fieldReport {
  background: linear-gradient(to bottom, $white 0%, $gray-5 100%);
  position: relative;
  margin-bottom: 190px;
  overflow: auto;

  @media (max-width: $tablet) {
    overflow: unset;
  }
}

.stepList {
  z-index: 2;

  @media (min-width: $tablet) {
    min-width: 280px;
    max-width: 375px;
    min-height: calc(100vh - 380px);
    box-shadow: 6px 0px 25px 0px rgb(179, 181, 185);
    margin-right: $xl;
  }
}

.reportColumns,
.verifyReportColumns {
  display: flex;

  @media (min-width: $tablet) {
    > :last-child {
      flex: 1;
    }
  }

  @media (max-width: $tablet) {
    > :first-child {
      flex: 1;
    }
  }
}

.actionFooter {
  position: absolute;
  z-index: 2;
  padding: 10px;
  background-color: #272f3f;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
}

.verifyReportColumns {
  display: flex;
  padding-bottom: 100px;
  overflow-x: hidden;
  flex-direction: column;
}

.stepRender {
  background-color: $gray-5;
  margin-left: -25px;

  > :first-child {
    box-shadow: unset;
    margin-top: unset;
  }
}

.iOSReport {
  height: 5px;
  width: 100%;
  background: transparent;
}

.noPaddingReport {
  margin-bottom: 60px;

  @include tablet-desktop {
    margin-bottom: 0;
  }
}

.topStatus {
  padding: $xl $l;
  border-bottom: 1px solid $gray-3;
  display: flex;
  align-items: center;
}

.topStatusMargin {
  margin-top: 65px;
}

.statusMargin {
  margin-top: 65px;
}

.formStatus {
  margin-left: $s;
  line-height: 14px;
}

.loadingSteps {
  border-bottom: 1px solid $gray-3;
  height: 400px;
}

.step {
  margin-top: $s;
  box-shadow: 0px -2.5px 6.3px 0.2px rgba(0, 0, 0, 0.2);
}

.errorBanner {
  text-align: center;
  background-color: $pink-1;
  max-height: 0;
  padding: 0 $m;
  box-sizing: border-box;
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 10;
  @include transitionify(all);
}

.visibleErrorBanner {
  composes: errorBanner;
  max-height: 108px;
  padding: $l $m;
}

.errorTitle {
  margin-bottom: $s;
  letter-spacing: 0.25px;
}

.submitError {
  margin: 0 0 $s;
}

.pendingFooter {
  background-color: $gray-1;
  bottom: 90px;
  left: 0;
  right: 0;
  z-index: 1;
  padding: $m;
  text-align: center;
  transition: bottom 0.5s ease-in-out;
}

.footerOnTop {
  position: absolute;
  bottom: unset;
  top: 0;
}

.pendingCopy {
  font-family: $WesFY-Bold;
  font-size: 12px;
  margin-bottom: $xxs;
}

.notCheckedIn {
  background-color: $pink-1;
}

.defaultStep {
  padding: 50px;
  text-align: center;
}

.flexStep {
  flex: 1;
}

.footerNote {
  text-align: center;
  font-family: $WesFY-Bold;
  font-size: 14px;
  margin-top: $s !important;
  color: #e4a026;

  span {
    color: white;
  }
}

.footerNoteGreen {
  @extend .footerNote;
  color: $teal-1;
}

.secondaryFooterAction {
  @media (min-width: 768px) {
    display: none;
  }

  @media (max-width: 767px) {
    margin-right: $s;
    width: auto;
    border-width: 3px;
    border-color: #cde1fd;
    padding: 0 $m;
  }
}
