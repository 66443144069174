.contactFirstRow {
  display: flex;
  flex-direction: row;
  > :first-child {
    flex: 1.15;

    > input {
      border-right: 1px solid #ebf1f7;
    }
  }

  > :last-child {
    flex: 1;
  }
}
