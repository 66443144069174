@import "~styles/variables";

.default {
  position: relative;
  border-radius: $border-radius;
}

.title {
  align-items: center;
  background-color: rgba($gray-3, 0.8);
  bottom: 0;
  display: flex;
  height: 30%;
  position: absolute;
  text-align: center;
  width: 100%;

  @include print {
    display: none;
  }
}

.titleText {
  font-size: 10px;
  padding-left: $s;
}

.none {
  composes: titleText;
  padding-top: $s;
}

.remove {
  position: absolute;
  top: -$s;
  right: -$s;
  z-index: 1;
  background-color: $pink-1;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    @include focus-glow;
    border-radius: 50%;
  }
}

.compactRemove {
  top: $xs;
  right: $xs;
}

.selectable {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 9;
  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
}

.selected {
  background: rgba(0, 0, 0, 0.7);
}

.selectableIcon {
  position: absolute;
  right: $xs;
  top: $xs;
  display: flex;
  align-items: center;
  justify-content: center;
}
