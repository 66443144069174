@import "~styles/variables";

.noOrgPage {
  flex: 1;
  width: 100vw;
  height: 100vh;
  height: fix-vh(100vh);
  background-color: $gray-1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  margin: $xxl;
  flex: 0.48;
}

.pinataLogo {
  margin-top: -($m + 1);
  margin-bottom: $xs;
}

.pinataLogo {
  margin-left: -($m + 1);
}

.title {
  margin-bottom: $xl;
}

.description {
  margin-top: $s;
}

.button {
  margin: $m 0 $m;
}

.invitationList {
  margin-top: $s;
}

.invitation {
  margin-top: $xs;
}
