@import "~styles/variables";

.copiesInput {
  background-color: $gray-1;
  color: $white;
  font-size: 18px;
}

.copiesBox {
  width: 80px;
  height: 55px;
  --button-size: 26px;
  --button-color: #6088e5;
}
