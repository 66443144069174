@import "~styles/variables";

.container {
  padding: $m;
  background-color: $teal-1;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 5px;
}

.counter {
  margin-bottom: $s;
  font-weight: bold;
}

.pills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.row {
  display: flex;
  flex-direction: row;
}
