@import "~styles/variables";

.modal {
  display: flex;
  flex-direction: column;
  padding: 0;
  max-width: 550px;

  @include tablet-desktop {
    height: 600px;
  }
}

.header {
  height: 87px;
  box-sizing: border-box;
  padding: $modal-spacing;
  background-color: $gray-1;

  @include tablet-desktop {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  display: flex;
  align-items: center;
}

.input {
  flex: 1;

  @include input;
  color: $white;
  font-size: 20px;
  font-family: $Lato-Heavy;

  &:focus {
    outline: 0;
  }
}

.clear {
  cursor: pointer;
  opacity: 1;
  @include transitionify(opacity);
}

.clearHidden {
  composes: clear;
  opacity: 0;
}

.fullSize {
  padding: $xxl;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > * {
    text-align: center;
  }
}

.loader {
  composes: fullSize;
  margin-top: -$m;
}

.list {
  flex: 1;
  overflow-y: auto;
}

.footer {
  padding: $m $xxl;
  border-top: 1px solid #dcdcdc;
}

.buttons {
  display: flex;
}

.selectedItems {
  position: relative;
  margin-bottom: $m;
}

$scroll-indicator-width: 50px;

.selectedItems::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: $scroll-indicator-width;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.selectedItemsWrapper {
  display: flex;
  overflow-x: auto;
  padding-right: $scroll-indicator-width;
}

.spacerAtEnd {
  display: block;
  min-width: $scroll-indicator-width;
  height: 15px;
  background-color: $white;
}

.newProgram {
  background-color: $blue-1;
  display: flex;
  justify-content: center;
  padding: $l 0;
  @include safe-bottom(padding, $l);
}

.question {
  margin-right: $xs;
}

.headerName {
  color: $white;
}

.activeHeader {
  background-color: $teal-1;
}

.draftHeader {
  background-color: rgba(0, 0, 0, 0.1);
}
