@import "~styles/variables";

$padding: $s;

.listFilter {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
}

.compactListFilter {
  width: 100%;
}

.searchBox {
  display: flex;
  padding: $padding;
  background-color: $gray-5;
  align-items: center;
}

.searchInput {
  margin-left: $s;
  flex: 1;
  font-family: $Lato;
  font-size: 12px;
  color: $gray-1;
  border: 0;
  background-color: transparent;

  &:focus {
    outline: 0;
  }
}
