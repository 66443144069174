@import "~styles/variables";

.container {
  position: relative;
  z-index: 2;
  padding: $s;
  background-color: $gray-1;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  height: 56px;
  box-sizing: content-box;
}

.cancel {
  margin-right: $s;
}

.button {
  width: 100%;
  flex: 1;
  border-radius: 0;
  font-family: $WesFY-Black;
  letter-spacing: normal;
  font-size: 18px;
  text-transform: uppercase;
}

.errorMessage {
  margin-top: $xs;
}

.note {
  text-align: center;
  font-family: $WesFY-Bold;
  margin-top: $s !important;
}

.header {
  margin-bottom: $s;
}
