@import "~styles/variables";

.tableWrapper {
  position: relative;
  overflow: auto;
}

.selector {
  display: block;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
  border: 2px solid $teal-1;
  border-radius: $border-radius;
  box-sizing: border-box;
  opacity: 0;
  z-index: 4;
  transition: 0.2s linear opacity, 0.2s linear top, 0.2s linear right, 0.2s linear bottom, 0.2s linear left;
  pointer-events: none;
}

.header > td {
  position: sticky;
  top: 21px;
  background-color: $white;
  box-shadow: $shadow;
}

.table {
  border-collapse: collapse;

  > tbody {
    > tr {
      > td,
      > th {
        padding: $xs;
        white-space: nowrap;
        vertical-align: center;
        font-family: $Lato;
        font-size: 12px;
        color: $gray-1;

        &:not(:first-child) {
          min-width: 50px;
        }
      }

      > td {
        border: 1px solid $gray-5;
        height: 32px;
      }

      > td:first-child,
      > th {
        background-color: $gray-2;
        color: $white;
        text-align: center;
        position: sticky;
      }

      > td:first-child {
        border: 0;
        left: 0;
        z-index: 2;
        text-align: center;
      }

      > th {
        top: 0;
        left: 0;
        z-index: 3;
      }
    }
  }
}
