@import "~styles/variables";

.titleContainer {
  min-height: 24px;
}

.boxMargin {
  margin-top: $s;
}

.titleBox {
  font-family: $WesFY-Bold;
  font-size: 18px;
  padding: 0;
}
