@import "~styles/variables";

.modal {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background-color: $gray-5;

  @include tablet-desktop {
    max-height: 100%;
  }
}

.content {
  flex: 1;
  padding: $modal-spacing;
  overflow-y: auto;
}

.copy {
  margin-bottom: $m;
}

.memberType {
  margin-bottom: $s;
}

.userTypeOption {
  margin-bottom: $s;

  > :first-child {
    align-self: flex-start;
    padding-top: 2px;
  }

  > :last-child {
    font-size: 15px;
  }
}

.proTip {
  font-family: $WesFY-Black;
  color: $pink-1;
  letter-spacing: 0.6px;
  font-size: 11px;
}

.canSelfAssign {
  padding: 15px;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 12px;
}

.canSelfAssignLabel {
  color: $gray-1;
  font-size: 12px;
}
