@import "~styles/variables";

.pill {
  display: inline-flex;
  align-items: center;
  background-color: $gray-1;
  border-radius: $border-radius;
  padding: $xs;
  margin: 0 $xs $xs 0;
}

.fullWidth {
  width: 100%;
  justify-content: space-between;
}

.remove {
  height: 15px;
  cursor: pointer;
}

.label {
  letter-spacing: 0.7px;
  font-weight: bold;
  // white-space: nowrap;
  // text-overflow: ellipsis;
}

.pillData {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
