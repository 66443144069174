@import "~styles/variables";

.container {
  width: 100%;
  background-color: $blue-3;
  margin-top: -$l;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.closed {
    padding-bottom: $s;
  }

  &.open {
    height: 100%;
  }

  > .detailsPill {
    cursor: pointer;
    border-color: $blue-1;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    margin-top: $s;
    padding: 0 $l;

    &:hover {
      background-color: transparent;
    }
  }

  > .desktopPill {
    align-self: flex-start;
    margin-left: $m;
  }
}

.detailsPillChildren {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $blue-1;
  gap: $xs;
  align-items: center;
}

.sectionLabel {
  color: #262838;
  font-weight: 700;
}

.sections {
  width: 100%;

  > :not(:last-child) {
    margin-bottom: -$xxl;
  }
  > * {
    padding: $l;
  }
}

.desktopSections {
  margin-left: $xxl;
}

.assets {
  padding: $s 0;
  display: flex;
  flex-direction: column;
  gap: $m;
}

.asset {
  display: flex;
  align-items: center;
  gap: $xs;
}

.textBody {
  display: inline;
  margin-left: $xs;
}

.images {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagesDesktop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.thumb {
  position: relative;
  .searchIconWrapper {
    position: absolute;
    left: 130px;
    top: -$s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #2d85bf;
    z-index: 1;
  }
  .imagesIconWrapper {
    position: absolute;
    bottom: -3px;
    left: $xs;
    z-index: 1;

    .picsCount {
      background-color: #2d85bf;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -$xs;
      right: -$s;
      border: 1px solid $white;
      color: $white;
      font-weight: 900;
      font-size: 12px;
    }

    .picsCountSingle {
      width: $l;
      height: $l;
      border-radius: calc($s + 2px);
    }

    .picsCountMultiple {
      width: 40px;
      height: 40px;
      border-radius: calc($l + 2px);
    }
  }
}
