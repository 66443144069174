@import "~styles/variables";

$calendar-size: 200px;

:root {
  --calendar-size: #{$calendar-size};
}

.calendar {
  width: $calendar-size;
  width: var(--calendar-size);
  height: $calendar-size;
  height: var(--calendar-size);
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex: 1 auto;
}

.cell {
  border-radius: 2px;
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 1.5px;
  user-select: none;
  @include transitionify(background-color);

  &:not(:last-child) {
    margin-right: 1.5px;
  }
}

.cell:hover {
  background-color: $blue-3;
}

.active {
  background-color: $teal-1 !important;
}

.active:hover {
  background-color: darken($teal-1, 20%);
}
