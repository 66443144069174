@import "~styles/variables";

.actionText {
  font-size: 10px;
  text-align: center;
  color: $white;
  font-family: $WesFY-Black;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.actionsContainer {
  padding: $m;
  background-color: $gray-2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90px;
  @include print {
    display: none;
  }
}

.dashboardBtn {
  height: 57px;
  outline: 3px $blue-2 solid;
  color: $blue-2;
  font-family: $WesFY-Black;
  font-size: 14px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}

.otherOptions {
  composes: actionText;
  font-size: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  line-height: 1px;

  > span {
    padding: 0 $xs;
    margin: $l 0;
  }
}

.otherOptions:before,
.otherOptions:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid $gray-3;
  margin: auto;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: $xs;
}

.actionButton {
  border: 2px solid $gray-3;
  height: 45px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  composes: actionText;
  padding: 13px 8px;

  > svg {
    margin-right: $s;
  }
}

.close {
  composes: actionText;
  color: $pink;
  font-size: 12px;
}

.addToCalendarContainer {
  flex-direction: column;
  width: 100%;
  height: auto;
}

.calDescription {
  composes: actionText;
  line-height: $s;
  text-align: left;
  text-transform: none;
  font-weight: 100;
  margin: $xs 0;
  padding: 0;
  font-size: 8px;
}
