@import "~styles/variables";

.table {
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    text-align: center;
    padding-right: $m;
    border: 0;
  }

  td:first-child,
  th:first-child {
    padding-left: $m;
  }

  td:last-child,
  th:last-child {
    padding-right: $m;
  }

  th {
    height: 32px;
    color: white;
    background-color: $gray-3;
  }

  td {
    height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

th.roleTitle,
td.roleTitle {
  text-align: left;
}

.tableWrapper {
  background-color: $white;
  height: 350px;
  overflow: auto;
}

.roleDetails {
  > svg {
    @include transitionify(fill);
  }

  &:focus {
    outline: 0;

    > svg {
      fill: darken($blue-2, 20%);
    }
  }
}

.roleSubtotal {
  min-width: 100px;
}

.withOperator {
  position: relative;

  &::after {
    position: absolute;
    top: calc(50% - 5px);
    font-size: 10px;
    font-family: $WesFY-Bold;
    font-weight: 900;
    color: $gray-3;
  }
}

.withMulOperator {
  composes: withOperator;

  &::after {
    content: "✕";
    left: -11px;
  }
}

.withEqOperator {
  composes: withOperator;

  &::after {
    content: "＝";
    left: -13px;
  }
}

.content {
  margin-bottom: $m;
}

.tableFooter {
  background-color: $gray-3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: $m;
}

.gigTotal {
  margin-left: $xxl;
  text-align: center;
}

.items {
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    :first-child {
      font-family: $WesFY-Bold;
      font-size: 10px;
      line-height: 1.8;
      min-width: 25%;
    }
    :last-child {
      flex: 1;
      font-family: $Lato;
      font-size: 14px;
      font-weight: 100;
      line-height: 1.29;
    }
  }
}
