@import "~styles/variables.scss";

a > .base {
  font-weight: lighter;
}

.base {
  margin: 0;
  padding: 0;
}

.em {
  font-style: italic;
}

.monospace {
  font-family: monospace;
}

.lato {
  font-family: $Lato;
}

.lato.bold {
  font-family: $Lato-Bold;
}

.lato.bold.em {
  font-family: $Lato-BoldItalic;
}

.wes {
  font-family: $WesFY-Black;
}

.wesR {
  font-family: $WesFY-Regular;
}

.wes.bold {
  font-family: $WesFY-Bold;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.placeholder {
  color: $gray-4;
}

.inline {
  display: inline-block;
}

.always {
  text-decoration: underline;
}

.hover:hover {
  text-decoration: underline;
}

.always,
.hover {
  cursor: pointer;
}

.never {
  text-decoration: none;
}

.noSelect {
  user-select: none;
  cursor: default;
}
