@import "~styles/variables";

.pill {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1.5px solid $teal-1;
  height: $xl;
  border-radius: $m;
  align-items: center;
  padding: 0 $s;
  margin-right: 3px;
  margin-bottom: 3px;
  @include transitionify(background-color);

  &:hover {
    background-color: $teal-2;
  }
}

.blue {
  border-color: $blue-2;
  background-color: $gray-5;
}

.remove {
  margin-left: $xs;
}
