@import "~styles/variables";

.note {
  display: flex;
  align-items: center;
  padding: $l $xxl;
  background: rgba(238, 238, 238, 0.9)
    repeating-linear-gradient(135deg, transparent, transparent 4px, rgba(0, 0, 0, 0.05) 4px, rgba(0, 0, 0, 0.05) 5px);

  @include mobile {
    padding: $m;
  }
}
