@import "~styles/variables";

.item {
  display: flex;
  flex: 1;
  align-items: center;
}

.name {
  margin-left: $m;
}

.go {
  display: flex;
  align-items: center;
}

.link {
  margin-right: $xxs;
  text-decoration: none !important;
  line-height: 17px !important;
}

.atCapacityText {
  margin-left: 12px;
  font-family: $WesFY-Bold;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.08em;
  color: #eb356c;
}

.atCapacityItem {
  background-color: #fdeaf0;
}

.iconWrapper {
  margin-left: $m;
  margin-right: 6px;
}
