@import "~styles/variables";
@import "../../../../dashboard";

.placeholder {
  background-color: $white;
  flex: 1;
  width: 100%;
}

.placeholderFilters {
  height: 48px;
  border-top: 1px solid #ced1d7;
  border-bottom: 1px solid $gray-3;
}

$border: 1px solid #e0e5eb;

.placeholderRow {
  display: flex;
  height: 250px;
  padding: $s $m;
  border-bottom: $border;

  > * {
    flex: 1;
  }

  > :first-child:not(:last-child) {
    margin-right: $m;
  }
}
