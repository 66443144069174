@import "~styles/variables";

.importer {
  display: flex;
  padding: 0;
  min-width: 900px;
  max-width: 1300px;
  max-height: calc(100vh - #{$xl});
  min-height: calc(100vh - #{$xl});
}

.close {
  position: absolute;
  top: $s;
  right: $s;
}

.map {
  position: relative;
  background-color: $gray-1;
  overflow: auto;
  width: 320px;
  display: flex;
  flex-direction: column;
}

.mapContent {
  padding: $m;
  flex: 1;
}

.headerSelect {
  margin: $m 0;
}

.mapFooter {
  position: sticky;
  box-sizing: border-box;
  text-align: end;
  padding: $m;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 6;
  background-color: darken($gray-1, 5%);
  box-shadow: $shadow;
}

$tabs-height: 56px;

.preview {
  flex: 1;
  display: grid;
  grid-template-rows: 0.5fr $tabs-height 0.5fr;
}

.dropZone {
  height: calc(100% - #{$m * 2});
  display: flex;
  padding: $m;

  > div {
    flex: 1;
  }
}

.fileName {
  margin: 0 $s 0;
}

.tabs {
  position: sticky;
  left: 0;
  bottom: 0;
  z-index: 4;
  height: $tabs-height;
  padding: 0 $m 0 32px;
  background-color: $gray-1;
  box-shadow: $shadow;
  display: flex;
  align-items: center;
}
