@import "~styles/variables";
@import "~styles/bg-pattern";

.gigTitle {
  font-weight: 100;
}

.simpleHeaderText {
  display: flex;
  padding: 30px 0 23px 0;
  justify-content: center;
  flex-direction: column;
}

.centeredSimpleHeaderText {
  composes: simpleHeaderText;
  align-items: center;
}

.header {
  display: flex;
  justify-content: center;
  position: relative;
  padding: $l;

  @include bg-pattern(#101827, #3a1c33);

  &.verified {
    @include bg-pattern(#111827, #204545);
  }

  &.rejected {
    @include bg-pattern(#1c1c1c, #363636);
  }

  @include transitionify(background);
}

.headerContent {
  display: grid;
  width: 100%;
}

.topHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.backButtonLabel {
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 24px;
}

.orgName {
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 24px;
  text-align: start;
}

.roleTitle {
  font-size: 22px;
  letter-spacing: 1px;
}

.closeBtn {
  width: 20px;
  height: 20px;
}

.overlay {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 20;
}

.overlayContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.menu {
  background: #7b79ba;
  padding: $xxxl $xl;

  ul > li {
    color: white;
    font-weight: 100;
    font-size: 20px;
    margin: $xxxl 0;
    cursor: pointer;

    &:first-child {
      margin-top: 0;
    }
  }

  button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: white;
    font-family: $WesFY-Black;
    font-size: 14px;
    cursor: pointer;
  }
}

.closeArea {
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
  padding: 20px;
  cursor: pointer;
  flex-grow: 1;
}

.noScroll {
  overflow: hidden;
  height: 100vh;
}

.cancelling {
  display: flex;
  align-items: flex-end;
}

.menuItemErrorMessage {
  font-size: 16px;
  a {
    color: $blue-3;
  }
}
