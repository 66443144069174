@import "~styles/variables";

.programBudget {
  min-width: unset;
}

.sectionLabel {
  display: block;
  color: $gray-2;

  margin-bottom: $m;

  &:not(:first-child) {
    margin-top: $l;
  }
}

.clearDates {
  margin-bottom: $m;
}

.approx {
  display: inline-block;
  font-family: $Lato;
  font-style: italic;
  font-size: 12px;
  color: $gray-3;
  user-select: none;
}

.noRightMargin {
  flex: 0.5;
  margin-right: 0 !important;
}

.globalLabel {
  color: $teal-1;
}

.diffDescription {
  margin-top: $s;
  margin-bottom: $m;
}

.numberContainer > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: $m;
}

.numberInput {
  box-shadow: none;
  height: 73px;
}

.radioButton {
  margin-top: 8px;

  &:first-child {
    margin-right: $m;
  }
}

.optionalSection {
  border-top: 1px solid $gray-4;
  padding-top: 16px;
}

.taskCapacityLimitText * {
  color: $pink;
}

.capacityLimitSwitchLabel.capacityLimitSwitchLabel {
  order: 2;
  color: $gray-1;
  margin-left: $s;
  font-size: 16px;
  font-family: $Lato-Bold;
}

.capacityLimitSwitchContainer.capacityLimitSwitchContainer {
  justify-content: flex-start;
}

.autoActivationSwitchLabel.autoActivationSwitchLabel {
  order: 2;
  color: $gray-2;
  margin-left: $s;
  font-size: 14px;
  font-family: $Lato;
}

.autoActivationSwitchHighlight * {
  color: $pink;
}

.autoActivationSwitchContainer.autoActivationSwitchContainer {
  justify-content: flex-start;
}

.flexContainer {
  display: flex;
}
