@import "~styles/variables";

.blurContent {
  > * {
    filter: blur(15px);
  }
  pointer-events: none;
}

.infoBar {
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  > :first-child {
    margin-boottom: $s;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.link {
  margin-top: $m;
  a {
    color: $blue-2;
  }
}
