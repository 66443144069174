@import "~styles/variables";

.statusPill {
  margin: 0 0 0 $s;
}

.unverifiedAccount {
  color: $gray-3;
}

.you {
  composes: statusPill;
  background-color: $blue-2;
}

.pending {
  composes: statusPill;
  background-color: $gray-3;
}

.nameCell {
  display: flex;
  align-items: center;
}
