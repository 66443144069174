@import "~styles/variables";

.field {
  display: flex;
  margin-bottom: $s;
}

.label {
  min-width: 110px;
  max-width: 110px;
  margin-right: $l;
}
