@import "~styles/variables";

.trainingMethodsSection {
  @include card;
  padding: $l $m $m $m;
  margin-bottom: $m;
}

.trainingMethodsTitle {
  margin-bottom: $s;
  color: $gray-1;
  font-size: 18px;
  font-family: $WesFY-Black;
}

.trainingMethodsGreenPart {
  color: $teal-1;
  font-size: inherit;
  font-family: inherit;
}

.checkboxLabel {
  font-size: 14px;
  margin-left: $s;
}

.trainingMethodOption {
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 33px;
  box-sizing: border-box;
  padding: $xs $xs $xs 0;

  > :first-child {
    min-width: 230px;
  }

  > :last-child {
    width: 290px;
  }
}

.trainingDescription {
  border-radius: 3px;
  background-color: $teal-1;
  color: white;
  font-size: 12px;
  font-family: $Lato;
  height: 23px;
  box-sizing: border-box;
  border: 0;

  padding: $xs $s;

  &::placeholder {
    color: white !important;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
  }

  &:disabled {
    background-color: $gray-3;
  }
}

.saveSection {
  margin-top: $m;
}

.inContentAddDocument {
  float: right;
  margin-top: $m;
}
