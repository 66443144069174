@import "~styles/variables";

.columnSource {
  padding: 0 $m $m;
}

.columnSelect {
  color: $white;
}

.columnArrowSelect {
  fill: $white;
}

.useSegment {
  margin-top: $s;
  display: inline-flex;
  align-items: center;
}

.segment {
  margin-top: $s;
  background-color: $gray-3;
  border-radius: $border-radius;

  * {
    color: white;
    fill: $white;
    background-color: transparent;
    border-color: $gray-2;
  }
}

.option {
  color: $black;
}
