@import "~styles/variables";

.label {
  text-transform: uppercase;
}

.changeContainer {
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: $m;
}

.rateContainer {
  display: flex;
  flex-direction: row;
}

.updateRow {
  display: flex;
}
