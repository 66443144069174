@import "~styles/variables";

.pagination {
  display: flex;
  align-items: center;
  padding: $s;
  background-color: $white;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.spacer {
  flex: 1;
}

.pages {
  display: flex;
  margin: 0 $m;

  @include mobile {
    margin: 0 $s;
  }
}

$page-label-size: 20px;

.page {
  cursor: pointer;
  display: block;
  font-weight: bold;
  margin: 0 $xxs;
  min-width: $page-label-size;
  height: $page-label-size;
  text-align: center;
  border-radius: $border-radius;
  user-select: none;
  padding: 0 3px;
  box-sizing: border-box;
  @include transitionify(all);

  &:focus {
    background-color: rgba(0, 0, 0, 0.1);
    outline: 0;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.activePage {
  composes: page;
  background-color: $gray-1 !important;

  > * {
    color: white;
  }
}

.edges {
  > :first-child {
    cursor: default;
    margin: 0 $m;
    user-select: none;
    font-weight: 900;
  }

  &:focus {
    outline: 0;

    > :first-child {
      color: $blue-2;
    }
  }
}

.first {
  composes: edges;

  > :first-child {
    margin-left: 0;
  }
}

.last {
  composes: edges;

  > :first-child {
    margin-right: 0;
  }
}

.lastPage {
  font-weight: normal;
}

.jumpTo {
  user-select: none;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

$jump-height: 25px;

.jumpInput {
  margin-left: $s;
  width: 30px;
  height: $jump-height;
  padding: 0 $xs;
  background-color: $gray-5;
  border: 0;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.jumpButton {
  box-sizing: border-box;
  padding-top: $xs;
  height: $jump-height;
  background-color: $blue-2;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  @include transitionify(background-color);

  &:focus {
    outline: 0;
    background-color: $blue-1;
  }
}

.jumpButtonDisabled {
  composes: jumpButton;
  background-color: $gray-3;

  &:focus {
    background-color: $gray-3;
  }
}
