@import "~styles/variables";

.locationForm {
  width: 400px;
  max-width: 400px;
}

.locationFormTable {
  overflow-y: auto;
  max-height: 550px;
}

.resetLocation {
  background: none;
  border: none;
  padding: 0;
  align-self: flex-end;
  display: flex;
  justify-content: end;
  width: 100%;
  margin-bottom: $xs;
}
