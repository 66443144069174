@import "~styles/variables";
@import "../styles.scss";

.detailsBlock {
  flex: 1;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-4;
  }

  > * {
    height: 100%;
  }
}

.expander {
  display: flex;
  align-items: center;
  align-self: flex-end;
}

.expanded {
  background-color: $blue-4;
}
