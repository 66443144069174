@import "~styles/variables";

.confirmBox {
  max-width: 510px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background-color: $gray-5;

  @include tablet-desktop {
    max-height: 100%;
  }
}

.header {
  padding-bottom: $s;
  height: 82px;
}

.body {
  background-color: $gray-1;
  padding: 0 $modal-spacing 0;
  color: $white;
}
