@import "~styles/variables";

.errors {
  display: flex;
  flex-direction: column;

  > * {
    margin-top: $s;
  }
}

.errorLink,
.errorLink:visited {
  color: $blue-2;
  font-weight: bold;
}

.errorLink:hover,
.errorLink:focus,
.errorLink:active {
  color: $blue-3;
  font-weight: bold;
}
