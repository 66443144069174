@import "~styles/variables";

$padding: $s;

.header {
  background-color: $white;
  padding: $padding;
  border-bottom: $border-light;
}

.stickyHeader {
  composes: header;
  position: sticky;
  z-index: 1;
}

.headerName {
  @include ellipsis;
  display: block;
}

.item {
  position: relative;
  cursor: pointer;
  padding: $padding;
  border-bottom: $border-light;
}

.focused {
  background-color: rgba(0, 0, 0, 0.05);
}
