@import "~styles/variables";

.horizontalSection {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  > :first-child {
    margin-right: $m;
  }

  > * {
    flex: 1;
    min-width: 290px;
  }
}
