@import "~styles/variables";

.contactListWrapper {
  background-color: $gray-1;
  padding: $m $m ($m - $s) $m;
}

.contactList {
  list-style: disc;
  list-style-position: inside;

  > li {
    font-family: $Lato;
    font-size: 12px;
    color: white;
    padding-bottom: $s;

    > a {
      color: white;
      text-decoration: underline;
    }
  }
}
