@import "~styles/variables";

.productName {
  @include ellipsis;
  max-width: 220px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.sectionHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $m;
}

.thinButton {
  align-items: center;
  display: flex;
  padding: 5px 15px;
}

.headerCount {
  color: $teal-1;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.docs {
  margin-left: 10px;
}

.brand {
  @include ellipsis;
  text-align: left;
  max-width: 100px;
}
