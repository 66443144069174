@import "~styles/variables";

.modal {
  display: grid;

  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr 328px;
  grid-template-areas:
    "header header"
    "mainForm sideForm"
    "footer footer";

  @include mobile {
    overflow: auto;
    grid-template-areas:
      "header header"
      "mainForm mainForm"
      "sideForm sideForm"
      "footer footer";
  }

  @include tablet {
    grid-template-areas:
      "header header"
      "mainForm mainForm"
      "sideForm sideForm"
      "footer footer";
  }
}

.message {
  margin: $m $m 0 $m;
}

.header {
  grid-area: header;
  background-color: $gray-2;
  padding: $xl $xl $v-modal-spacing $v-modal-spacing;

  @include mobile {
    padding: $xl $m;
    display: flex;
    align-items: center;

    > .headerContent {
      margin-left: $m;
      flex: 1;
    }
  }
}

.headerContent {
  display: flex;
  flex-direction: column;

  > .labels {
    align-items: center;
    margin-bottom: 10px;
    flex: grow;
    display: flex;
    justify-content: space-between;
  }
}

.headerBackIcon {
  padding: 5px;
}

// --- FORM STYLES ---
// These might be moved to a separate component for task editing

.disabledOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  padding: $v-modal-spacing;
  padding-top: 200px;
  text-align: center;

  @include mobile {
    padding-top: 50px;

    > * {
      font-size: 22px !important;
    }
  }
}

.mainForm,
.sideForm {
  background-color: $gray-5;
}

.mainForm {
  grid-area: mainForm;
  box-sizing: border-box;
  padding: $v-modal-spacing;
  max-width: 765px;
  overflow: auto;

  @include mobile {
    overflow: visible;
    padding: 0;
    padding-bottom: $xxl;
  }
}

.fieldSection {
  padding: $l 0;
  border-top: 1px solid $gray-4;
  display: flex;

  @include mobile {
    padding: $m;
    align-items: center;
  }
}

.fieldSectionTitle {
  @include mobile {
    display: none;
  }
}

.fieldSectionContent {
  margin-top: $xxs;
  margin-left: $m;
  flex: 1;
}

.pndField + .pndField {
  margin-top: $m;
}

.sideForm {
  grid-area: sideForm;
  box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $v-modal-spacing $xl;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.05));
  overflow: auto;
}

.sideFormSection {
  margin-bottom: $xxl;
}

.programOwnerNameContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.programOwnerNameIcon {
  margin-right: $s;
}

// --- FOOTER ---

.footer {
  grid-area: footer;
  padding: $xl $xl $xl $v-modal-spacing;
  display: flex;
  align-items: center;
  background-color: $gray-1;

  @include mobile {
    padding: $l;
    position: relative;
  }
}

.copies {
  margin-left: $m;

  @include mobile {
    margin-left: 0;
    margin-right: $m;
  }
}

.copiesIcon {
  @include mobile {
    position: absolute;
    top: $xl - $s;
    left: $xl - $s;
    padding-right: 2px;
    z-index: 1;
    background-color: $gray-1;
  }
}

.copiesChevron {
  margin: 0 $xs 0 $m;
}

.batchUploadButton {
  @include mobile {
    margin-top: $s;
  }
  font-size: 12px;
}

.programPicker {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: $m;
  > :first-child {
    flex: 1;
  }
}

.accessLevelNote {
  display: flex;
  flex: 1;
  align-items: end;
}
