@import "~styles/variables";
@import "../styles";

.container {
  align-items: center;
  background-color: rgba(39, 47, 63, 0.85);
  background-position: center;
  display: flex;
  flex-direction: column;
  padding: $xxl;
  position: relative;
  box-sizing: border-box;
  min-height: 50vh;
  min-height: fill;

  @include mobile {
    min-height: 50vh;
  }
}

.checkInTime {
  margin-bottom: $xl;
  text-align: center;
}

.orgName {
  line-height: 28px;
}

.timeAndDistance {
  display: flex;
  flex-direction: row;
  margin: $xxl 0px;
}

.greenSquare {
  align-items: center;
  background-color: $teal-1;
  display: flex;
  flex-direction: column;
  height: 130px;
  justify-content: center;
  margin: 2px;
  width: 130px;
}

.redSquare {
  background-color: $pink-1;
}

.smallerText {
  font-size: 12px;
}

.center {
  text-align: center;
}

.checkinButton {
  font-family: $WesFY-Black;
  font-size: 18px;
  letter-spacing: unset;
}

.cancelButton {
  font-size: 18px;
  margin-right: unset !important;
}

.footer {
  background-color: black;
  bottom: -60px;
  display: flex;
  height: 80px;
  left: 0;
  position: absolute;
  width: 100%;
  button {
    margin: $s;
  }
  > :last-child {
    flex: 1;
  }
}

.alignLeft {
  left: 0;
}

.footerHeight {
  height: 75px;
  padding-top: $xs;
}

@include mobile {
  .checkOutFooter {
    margin-bottom: $s;
  }
}

.proximityDesc {
  font-size: 18px;
  color: $white;
}
