@import "~styles/variables";

.picker {
  background-color: $blue-2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include transitionify(background-color);
  border-radius: $border-radius;
}

.pickerExtra {
  composes: picker;
  background-color: $blue-3;

  .pickerLabel {
    color: $blue-2;
  }

  > svg {
    fill: $blue-2;
  }
}

.disabledPicker {
  composes: picker;
  background-color: $gray-3;
}

.pickerLabel {
  margin-top: $xs;
  font-family: $WesFY-Bold;
  letter-spacing: 0.7px;
  font-size: 12px;
}

.imagesArray {
  display: flex;
  flex-wrap: wrap;
  margin-top: $s;
  margin-bottom: -$m;

  > :not(:last-child) {
    margin-right: $m;
  }

  > * {
    margin-bottom: $m;
  }

  > .vertical {
    width: 100%;
    display: flex;
    align-items: center;

    > * {
      margin-right: $m;
    }
  }
}

.upload {
  composes: picker;
  background-color: $teal-1;
}

.uploadError {
  composes: picker;
  background-color: darken($pink-2, 20%);
  position: relative;
}

.textBox {
  background-color: $white;
  max-height: 60px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  flex: 1;

  > span {
    padding-top: 5px !important;
    top: 2px !important;
  }
}

.textBoxInput {
  padding-top: 42px;
  max-height: inherit;
}

.remove {
  position: absolute;
  top: -$s;
  right: -$s;
  z-index: 1;
  background-color: $pink-1;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    @include focus-glow;
    border-radius: 50%;
  }
}

.fileButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
  background-color: $gray-1;
}

.fileButtonImg {
  composes: fileButton;
  padding: 0;
  border: 0;
  > img {
    border-radius: 3px;
  }
}
