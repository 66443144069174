@import "~styles/variables";

.modal {
  max-width: 500px;
  background-color: $gray-5;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include tablet-desktop {
    max-height: 100%;
  }
}

.userModal {
  width: 100%;
  min-height: 500px;
}

.userInfo {
  padding: $l $xxl $xxl $xxl;
}

.userName {
  margin-bottom: $xl;
}

.infoTable {
  overflow: auto;
}

.infoRow {
  display: flex;
  align-items: flex-start;
  flex-basis: auto;
  margin-top: $l;

  @include mobile {
    flex-direction: column;
  }
}

.title {
  flex: 1;
  min-width: 140px;
  margin-right: $s;
  font-size: 14px;
  line-height: 14px;

  @include mobile {
    margin-bottom: $xs;
  }
}

.loadingDetails {
  height: 250px;
}

.filesContainer {
  display: flex;
  flex-direction: column;
}

.button {
  margin-top: 22px;
  text-transform: uppercase;
}

.submitContainer {
  padding: 5px;
}

.tagsContainer {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
}

.tag {
  font-family: $Lato;
  font-size: 14px;
  line-height: 18px;
  color: $gray-1;
  text-transform: capitalize;
  text-decoration: underline;
  margin-right: 5px;
}

.internalInfoContainer {
  flex: 1 0 auto;
  padding: 5px;
  flex-direction: row;
}

.notes {
  min-height: 60px;
}

.moneyBoxInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 20px;
  justify-content: space-between;

  > .rate {
    color: $gray-3;
    font-family: $Lato;
  }
}

.documentsTable {
  margin-top: 20px;
}

.label {
  margin-bottom: $s;
}

.section {
  margin-top: $s;
}

.noBorderMoneyBox {
  box-shadow: unset;
}

.salaried {
  > p {
    color: $gray-1 !important;
  }
}

.textBox {
  text-transform: uppercase;
}

.legalName {
  flex-grow: 2;
}
