@import "~styles/variables";
@import "../Table/Row/styles";

$block-radius: $row-border-radius - $row-padding;

.block {
  flex: 1;
  min-width: 0;
  min-height: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: $m $s;
  font-size: 12px;
  background-color: $white;
  position: relative;

  &:focus {
    @include focus-glow();
  }

  @include mobile {
    flex-direction: row;
  }
}

.transparent {
  background-color: transparent;
}

.action {
  position: absolute !important;
  background-color: $blue-2;
  box-shadow: $shadow;
  padding: $s;
  border-radius: 50%;
  top: -7px;
  right: -7px;
  transition: 0.2s transform ease;
  transition-delay: 0;
  transform: scale(0);
  z-index: 2;

  &:focus {
    border-radius: 50%;
    background-color: $blue-2;
  }
}

.block:hover,
.block:focus,
.editing {
  &:not(.transparent) {
    background-color: $blue-4;
    z-index: 1;

    > .action {
      transition-delay: 0.4s;
      transform: scale(1);
    }

    @include mobile {
      z-index: 0;
    }
  }
}

.editing > .action {
  background-color: $gray-2;
}

.detailed {
  padding: $m;
  @include transitionify(background-color);
}

.regular {
  height: 75px;
  align-items: flex-start;

  &:first-child {
    border-top-left-radius: $block-radius;
    border-bottom-left-radius: $block-radius;
  }

  &:last-child {
    border-top-right-radius: $block-radius;
    border-bottom-right-radius: $block-radius;
  }
}

.compact {
  margin: 0;
}

.formContainer {
  min-width: 230px;
  max-width: 430px;
  display: block;
  background-color: $gray-1;
  padding: $m;
  box-sizing: border-box;
}

.submit {
  padding-top: $m;
}

.submitBtn {
  width: 100%;
  border-radius: 0;
  font-family: $WesFY-Black;
  font-size: 14px;
  height: 45px;
}
