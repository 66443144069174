@import "~styles/variables";

.tableWrapper {
  position: relative;
  overflow: auto;
}

.importing {
  overflow: hidden;
}

.importIndicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.beforeHeader > td {
  background: rgba(238, 238, 238, 0.9)
    repeating-linear-gradient(135deg, transparent, transparent 4px, rgba(0, 0, 0, 0.05) 4px, rgba(0, 0, 0, 0.05) 5px);
}

.table {
  border-collapse: collapse;

  > tbody {
    > tr {
      > td,
      > th {
        padding: $xs;
        white-space: nowrap;
        vertical-align: center;
        font-family: $Lato;
        font-size: 12px;
        color: $gray-1;

        &:not(:first-child) {
          min-width: 80px;
        }
      }

      > td {
        border: 1px solid $gray-5;
        height: 32px;
      }

      > td:first-child,
      > th {
        background-color: $gray-2;
        color: $white;
        text-align: left;
        position: sticky;
      }

      > td:first-child {
        border: 0;
        left: 0;
        z-index: 2;
        text-align: center;
      }

      > th {
        top: 0;
        left: 0;
        z-index: 3;
      }
    }
  }
}

.pill {
  margin: 0;
  margin-right: $xs;
}

.mismatchPill {
  composes: pill;
  opacity: 0.7;
}

.leaveBlank {
  color: rgba(0, 0, 0, 0.3) !important;
}

.invalid {
  text-decoration: line-through;
}

.warn {
  composes: invalid;
  background-color: rgba(255, 193, 16, 0.1);
}

.error {
  composes: invalid;
  background-color: $pink-2;
}

.doneContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttons {
  display: flex;

  > :first-child {
    margin-right: $m;
  }
}

.downloadFailedExplanation {
  width: 80%;
  text-align: center;
}
