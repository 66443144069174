@import "~styles/variables";

.message {
  font-family: $Lato;
  font-size: 13px;
  color: $pink-1;
  margin: 8px 0 0 0;
}

.inline {
  display: inline;
}
