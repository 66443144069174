@import "~styles/variables";

$padding: $m;

$item-height: 30px;
$visible-items: 4;
$selected-items-height: $item-height + $xs * 2;

.items {
  flex: 1;
  position: relative;
}

.rows {
  margin-bottom: $selected-items-height;
  &:focus {
    outline: 0;
  }
}

.item {
  height: $item-height;
  padding: 0 $padding;
  border-bottom: 1px solid $gray-5;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.selectedItem {
  background-color: $gray-5;
}

.selectedItems {
  padding: $xs 0;
  display: flex;
  flex-direction: column;
  border-top: 1px solid darken($gray-5, 5%);
  // background-color: $white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: $selected-items-height;
  overflow: hidden;
  box-sizing: border-box;
  @include transitionify(max-height);
  background-color: $gray-5;

  > * {
    min-height: $item-height;
    box-sizing: border-box;
    @include transitionify(all);
  }
}

.countBadge {
  position: absolute;
  bottom: -$xs;
  right: $s;
  opacity: 1;
  @include transitionify(opacity);
}

.selectedItemsMultiple {
  composes: selectedItems;

  > :first-child {
    padding-bottom: $xs;
    margin-bottom: $xs;
  }
}

.selectedItemsMultiple:hover {
  overflow: scroll;
  max-height: $item-height * $visible-items;

  > :first-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  > .countBadge {
    opacity: 0;
  }
}

.emptyState {
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

.zeroWrapper {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

.subtitle {
  word-break: break-all;
}

.addNewBtn {
  justify-self: flex-end;
  background: $gray-5;
  border: none;
  text-align: left;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  * {
    cursor: pointer;
  }

  > :last-child {
    margin-right: $xs;
  }
}
