@import "~styles/variables";

.cancelDetails {
  > div {
    display: flex;
    flex-direction: column;
  }
}

.userLink {
  color: white;
  text-decoration: underline;
}
