@import "~styles/variables.scss";

.env {
  background-color: $pink-1;
  padding: 5px;
  width: 120px;
  text-align: center;
  transform: rotate(-45deg);
  position: fixed;
  z-index: 99999999;
  top: 23px;
  left: -29px;
}
