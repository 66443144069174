@import "~styles/variables";

.shareWithPinata {
  background-color: $blue-2;
  padding: $l;
  margin-bottom: $m;
  @include transitionify(background-color);

  &:focus,
  &:hover {
    background-color: darken($blue-2, 5%);
  }

  &:active {
    background-color: darken($blue-2, 10%);
  }

  &[aria-disabled="true"] {
    background-color: $gray-3 !important;
    > * {
      cursor: not-allowed;
    }
  }
}

.shareWithPinata[disabled] {
  background-color: $gray-3 !important;
}

.shareWithPinataText {
  text-align: center;
}

.save {
  width: 100%;
}
