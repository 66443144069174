@import "~styles/variables";

// base

.link {
  font-family: $WesFY-Bold;
  letter-spacing: normal;
  box-shadow: none;
  @include transition-focus;

  &:focus:hover {
    box-shadow: none;
  }

  &[aria-disabled="true"] {
    color: $gray-3 !important;
    cursor: not-allowed;
  }
}

// kinds

.primary {
  color: $blue-2;
}

.secondary {
  color: $blue-2;
  font-family: $Lato;
  font-weight: normal;
}

.reverse {
  color: $white;
}

// variants

.v1 {
  font-size: 28px;
}

.v2 {
  font-size: 18px;
  line-height: 22px;
}

.v3 {
  font-size: 14px;
  line-height: 16px;
}

.v4 {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
}

// etc

.never {
  text-decoration: none;
}

.hover {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.always {
  text-decoration: underline;
}
