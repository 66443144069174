@import "~styles/variables";

.partnerList {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
  border-top: 1px solid $gray-3;
  padding-top: 16px;
}

.partnerName {
  color: $blue-2;
}

.partnerActivity {
  display: flex;
  align-items: center;
  background-color: #eaecf4;
  margin-top: 16px;
  padding-left: 10px;
}

.activityTasksCount {
  text-decoration: underline;
  font-family: $WesFY-Regular;

  .countNumber {
    font-family: $WesFY-Bold;
  }
}

.activityName {
  flex: 1;
}

.emptyState {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emptyStateSubHeader {
  margin-top: $xs;
}

.visibilityPermissionsRadioGroup {
  margin: 10px 0;
}

.removeFromProgramBtn {
  margin: 0;
  border: 0;
  padding: 0;
  color: $pink-1;
  background-color: inherit;
  font-family: $Lato;
  cursor: pointer;
}
