@import "~styles/variables";

$modal-header-size: 96px;

.header {
  display: flex;
  align-items: center;
  height: $modal-header-size;
  min-height: 80px;
  box-sizing: border-box;
  background-color: $gray-1;
  padding: $xl;
  // position: sticky;
  // top: 0;
  // left: 0;
  // z-index: 1;

  @include print {
    display: none;
  }
}

.title {
  display: flex;
  align-items: center;

  > svg {
    margin-right: $m;
  }

  > svg + div {
    margin-top: 3px;
  }
}

.close {
  margin-left: $xs;

  > svg {
    fill: $white;
  }

  $icon-desk-size: 20px;

  @include tablet-desktop {
    position: absolute;
    top: $xs;
    right: $xs;

    > svg {
      height: $icon-desk-size;
      width: $icon-desk-size;
      fill: $gray-3;
    }
  }
}
