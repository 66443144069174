@import "~styles/variables";

.container {
  position: relative;
}

.menu {
  @include card;
  position: absolute;
  top: 100%;
  z-index: 2;
  right: $xxs;
}

.trigger {
  display: flex;
  align-items: center;

  > button {
    &:focus {
      box-shadow: none;
    }
  }
}

.arrow {
  float: right;
  margin-left: -$m;
  @include transitionify(all);
}

.arrowUp {
  composes: arrow;
  transform: rotate(180deg);
}
