@import "~styles/variables";

.modal {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  max-height: 100%;
  flex: unset;
  //height: 900px;
  background-color: transparent;

  -webkit-print-color-adjust: exact !important;

  @include print {
    overflow: visible;
  }

  @media (max-width: $tablet) {
    max-height: unset;
    flex-direction: column;
  }
}

.leftSide {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: $white;
  overflow: auto;
  min-width: 480px;
  max-width: 650px;
  overflow-x: hidden;

  @include print {
    overflow: visible;
  }
}

.leftSide,
.detailsHeader {
  border-top-left-radius: $border-radius;
}

.actionsLoading {
  min-width: 300px;
}

.rightSide {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  //height: 500px;
  background-color: $gray-1;
  padding-bottom: $m;
  overflow-x: hidden;

  @include print {
    display: none;
  }

  @media (max-width: $tablet) {
    padding: unset;
  }
}

.mobileLeftSide {
  min-width: unset;
  width: 100%;
}

.mobileRightSide {
  position: absolute;
  width: 100%;
  padding-bottom: 0px;
  bottom: 0;
}

$header-height: 85px;

.detailsHeader {
  border-bottom: none !important;
  height: $header-height;
}

.detailsHeader,
.actions {
  width: 100%;
  display: flex;
  box-sizing: border-box;
}

.actions {
  background-image: linear-gradient(60deg, $blue-2 0%, $blue-2 35%, #6088e5 50%, $blue-2 85%, $blue-2 100%);
  background-size: 400% 100%;
  @include transitionify(background-position);
  height: $header-height;
}

.actions:not(:hover) {
  transition-delay: 300ms;
}

.actions:not(:hover),
.h3 {
  background-position: 38% 0;
}

.h2 {
  background-position: 48% 0;
}

.h1 {
  background-position: 58% 0;
}

.h0 {
  background-position: 68% 0;
}

.action {
  flex: 1;
  height: $header-height;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }

  background-color: transparent;
  @include transitionify(background-color);
}

.actionBody {
  background-color: $gray-2;
  padding: $xl;
}

.count {
  text-align: center;
}

.navButtons {
  margin: 25px -25px -25px -25px;
  display: flex;
  flex-direction: column;
  background-color: $gray-1;
}

.closeBtn {
  height: 65px;
  border-radius: 0;
  font-size: 14px;
  border: 0;
}

.close {
  position: absolute;
  top: 0;
  left: 0;
  width: 32.5px;
  height: 32.5px;
  padding: 7px;
  z-index: 2;
  background-color: #272f3f;
  border-bottom-right-radius: 100%;
  box-sizing: border-box;

  &:focus {
    border-bottom-right-radius: 100%;
    background-color: darken($gray-1, 10%);
  }

  > svg {
    stroke: white;
    stroke-width: 2px;
  }
}

.actionPlaceholder {
  margin: $xl $xl $m $xl;
  @media (max-width: $tablet) {
    display: none;
  }
}

.actionList {
  display: flex;
  flex-direction: column;
  padding: $m;
}

.actionItem {
  display: flex;
  align-items: center;
  margin-bottom: $xs;
}

.errors {
  margin-left: $s;
}

.gigTabs {
  background: $gray-1;
  color: white;
  display: flex;
  max-width: 1100px;
  :not(:last-child) {
    box-shadow: $shadow;
  }
}

.tab {
  display: flex;
  flex: 1;
  height: 60px;
  align-items: center;
  justify-content: center;
}

.activeTab {
  background: white;
}

.divider {
  border-top: rgba(0, 0, 0, 0.15) 1px solid;
  width: 100%;
  margin-bottom: $l;
  margin-top: $m;
}

.billing {
  display: flex;
  background: white;
  padding: $m $xxl;
  // test
  min-height: 800px;
  min-width: 800px;
}

.talentAndExpenses {
  flex: 1;
  padding-right: $xxl;
  max-width: 525px;
}

.talent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.paymentTitle {
  align-items: baseline;
  display: flex;
  margin-bottom: $m;
  :first-child {
    margin-right: $s;
  }
}

.tooltip {
  background: $gray-1;
  padding: $s;
  border-radius: $m;
}

.invoiceAndMessages {
  flex: 0.5;
  padding-left: $xxl;
  box-shadow: -22px 0px 15px -15px #00000014;
  border-left: 1px solid #4848480f;
  width: 350px;
  display: flex;
  flex-direction: column;
  > div:first-child {
    margin-bottom: $l;
  }

  > div:not(:first-child) {
    margin-bottom: $m;
  }
}

.gigCharges {
  display: flex;
  > div {
    flex: 1;
  }
}

.chargesDecimal {
  font-size: 18px;
}

.notificationSwitch {
  display: flex;
  align-items: center;
  > div:first-child {
    margin-right: $s;
  }
}

.fieldShadow {
  box-shadow: $shadow;
}

.userPicture {
  width: 120px !important;
  height: 120px !important;
}

.talentContainer {
  display: flex;
  margin-bottom: $xs;
}

.rateAction {
  display: flex;
  flex: 1;
  box-shadow: 0px 0px 2px 2px #c2e4fd;
  border-radius: 2px;
}

.rateContainer {
  display: flex;
  flex-direction: column;
  > :not(:last-child) {
    border-bottom: $border-light;
  }
  flex: 1;
}

.rates {
  display: flex;
  flex: 1;
  padding: $s;
  align-items: center;
  justify-content: space-between;
}

.talentTotal {
}

.addExpense {
  border-color: $blue-2;
  background-color: $blue-2;
  color: white;
  margin-right: unset;
}

.addExpenseItems {
  display: flex;
  align-items: center;
  > svg {
    margin-right: $s;
  }
}

.actionContainer {
  flex: 0.15;
  display: flex;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.authorizeBtn {
  box-shadow: unset !important;
  padding: unset;
  width: 100%;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  border-top-left-radius: unset;
}
