@import "~styles/variables";

.billing {
  display: flex;
  background: white;
  padding: $xl;
  flex-direction: column;
}
.talentAndExpenses {
  flex: 1;
  width: 100%;
}

.talent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.paymentTitle {
  align-items: baseline;
  display: flex;
  margin-bottom: $m;
  :first-child {
    margin-right: $s;
  }
}

.divider {
  border-top: rgba(0, 0, 0, 0.15) 1px solid;
  width: 100%;
  margin-bottom: $l;
  margin-top: $m;
}

.tooltip {
  background: $gray-1;
  padding: $s;
  border-radius: $m;
}

.totalTalent {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.clientExpenseItem {
  max-height: 80px;
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 2.5px;
  box-shadow: 0 0 5px 0 $gray-4;
  margin-bottom: 5px;

  .images {
    cursor: pointer;
    max-width: 100px;
    max-height: 80px;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
  }

  img {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
  }
  .details {
    display: flex;
    width: 100%;
    align-self: center;
    height: 100%;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 10px 0;
    }

    .leftPane {
      flex: 4;
      margin-left: 15px;
    }
    .rightPane {
      margin-right: 15px;
      text-align: right;
    }

    .onlyDateRightPane {
      justify-content: flex-end;

      > div {
        margin-bottom: 4px;
      }
    }
  }
}
