@import "~styles/variables";
@import "../../../../dashboard";

.rdashboard {
  flex: 1;
  background-color: $white;
  position: relative;
}

.iframe {
  flex: 1;
  width: 100%;
  border: 0;
  z-index: 1;
  position: relative;
}

.loadingIframe {
  composes: iframe;
  max-height: 0;
  box-sizing: border-box;
  overflow: hidden;
}

.images {
  padding: 0 ($l - $s) $l $l;
}

.downloadBtnGroup {
  display: flex;
  align-items: center;
  z-index: 0;
  position: absolute;
  right: 0;
  top: -22px;

  & > * {
    display: flex;
    align-items: center;
    overflow: hidden;
    @include transitionify(transform);
    transform: translateY(0);
    margin-right: 5px;

    &:focus,
    &:hover {
      outline: 0;
    }
  }
}

.downloadBtn {
  z-index: 0;
  position: absolute;
  right: 0;
  top: -22px;
  display: flex;
  align-items: center;
  overflow: hidden;
  @include transitionify(transform);
  transform: translateY(0);

  &:focus,
  &:hover {
    outline: 0;
  }
}

.waitingDownloadBtn {
  composes: downloadBtn;
  transform: translateY(120%);
}

.downloadText {
  font-size: 12px;
  font-family: $WesFY-Bold;
  color: $gray-1;
  cursor: pointer;
}

.warning {
  color: $pink-1;
}
