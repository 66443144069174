@import "~styles/variables";

.states {
  max-width: 550px;
  column-count: 3;
  font-size: 14px;
}

.stateCheckbox {
  margin: $xs 0;
}

.notes {
  color: $gray-3;
  font-family: $Lato;
  font-size: 14px;
  font-style: italic;
  margin: 0 0 $m $m;
}

.dropdownWrapper {
  background-color: $teal-1;
  border-radius: 5px;
  width: 200px;
  > div > select {
    color: white !important;
  }
}

.inputWrapper {
  border-radius: 3px;
  background-color: $teal-1;
  > input {
    color: white;
    font-size: 12px;
    height: 23px;
    padding: 5px 5px;
  }
}

.dropdown {
  font-size: 14px;
  padding: 10px 30px 10px 10px;
}

.arrow {
  top: 9px;
}

.capOperand {
  height: 17px;
  width: 90px;
  padding: $xs;
  height: 13px;
  > input {
    font-size: 12px;
    text-align: left;
    width: 100%;
  }
}

.demoKitSection {
  > div {
    padding: $m;
  }

  > div > div:not(:first-child) {
    border: 1px darken($gray-5, 5%) solid;
    border-radius: 3px;
    margin-bottom: $m;
  }

  > div > :first-child {
    border-bottom: 0;
  }
}

.demoKit {
  margin-bottom: $m;
  span {
    color: $gray-1;
    font-size: 18px;
    font-family: $WesFY-Black;
  }
  > :nth-child(2) {
    color: $teal-1;
  }
}

.expenseList {
  border: 0 !important;
  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > :first-child {
      width: 25%;
    }
    > :nth-child(2) {
      width: 55%;
    }
    > :last-child {
      width: 15%;
    }
  }
  > :not(:last-child) {
    margin-bottom: $xs;
  }
}

.expenseLabel {
  color: $gray-3;
  font-family: "WesFy-Bold";
  font-size: 10px;
  margin-top: 5px;
}

.label {
  font-size: 14px;
}

.pinkLabel {
  color: $pink-1;
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;
  font-family: inherit;
}

.programFilesLabel {
  display: block;
  padding-top: $m;
  padding-bottom: $s;
}

.filePicker {
  padding: 5px 15px 15px 15px;
}
