@import "~styles/variables";

.modal {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background-color: $gray-5;
}

.content {
  padding: $modal-spacing;
}

.copy {
  margin-bottom: $m;
}

.programSelect {
  margin-top: $xl;
}
