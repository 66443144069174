@import "~styles/variables";

.modal {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background-color: $gray-5;

  @include tablet-desktop {
    max-height: 100%;
  }
}

.form {
  flex: 1;
  flex-basis: auto;
  padding: $modal-spacing;
  overflow-y: auto;
}

.sku {
  flex: 0.5;
}

.hr {
  height: 1px;
  border: 0;
  border-top: 1px solid $gray-3;
  margin: 1em 0;
  padding: 0;
}

.docCheckbox {
  align-items: center;
  color: $gray-3;
  display: flex;
  font-family: $WesFY-Black;
  font-size: 14px;
  margin-bottom: $m;
  :first-child {
    margin-right: $xs;
  }
}

.newDocument {
  margin-top: $m;
}

.noBorderMoneyBox {
  box-shadow: unset;
}

.productPics {
  display: flex;
  flex-direction: column;
  gap: $s;

  > .picsHelp {
    font-family: $Lato;
    font-size: 12px;
    text-decoration: none;
    color: $blue-2;
    font-weight: 700;
  }
}

.refImageText {
  font-family: $WesFY-Black;
  font-size: 14px;
  color: $gray-3;
}
