@import "~styles/variables";

$radius: 20px;

.reportStatus {
  background-color: $gray-1;
  box-sizing: border-box;
  width: 280px;
  margin: 40px auto $xs auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $radius;
}

.noMessage {
  padding-bottom: $xl;
}

.icon {
  $size: 46px;
  border-radius: 50%;
  width: $size;
  height: $size;
  margin-top: div(-$size, 2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  margin: $s 0;
}

.body {
  padding: 0 28px;
  margin-bottom: -$xs;
}

.bodyWithMessage {
  composes: body;
  margin-bottom: $s;
}

.body,
.title {
  text-align: center;
}

.title > em {
  text-decoration: underline;
  font-style: normal;
}

@mixin report-color($color) {
  > .title > em {
    color: $color;
  }

  > .icon {
    background-color: $color;
  }
}

.returned {
  @include report-color($pink-1);
}

.rejected {
  > .title > em {
    color: $gray-3;
  }

  > .icon {
    background-color: $gray-3;
  }
}

.draft {
  padding: 10px;

  > .title {
    padding-top: 10px;
  }

  > .icon {
    background-color: $haydayLogo;
  }
}

.verified,
.submitted {
  @include report-color($teal-1);
}

$view-height: 40px;
$hide-height: 20px;

.toggleBtn {
  width: 100%;
  font-size: 10px;
  box-sizing: border-box;
  padding: 0;
  @include transitionify(all);
}

.reviewer {
  font-size: 10px;
}

.viewMessage {
  composes: toggleBtn;
  height: $view-height;
  line-height: $view-height;
  border-radius: $radius;

  &:focus {
    border-radius: $radius;
  }
}

.hideMessage {
  composes: toggleBtn;
  border-radius: 0 0 $radius $radius;
  height: $hide-height;
  line-height: $hide-height;

  &:focus {
    border-radius: 0 0 $radius $radius;
  }
}

.message {
  padding: $l;
  border-radius: $radius $radius 0 0;
  background-color: $white;
}

.messageText {
  white-space: pre-wrap;
}
