@import "~styles/variables";
@import "../../../dashboard";

.container {
  max-width: $max-page-width - 25px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  padding: 50px $side-padding;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.noReports > * {
  text-align: center;
}

.noReportsExplanation {
  margin-top: $s;
}

.placeholderTabs {
  display: flex;
  height: 45px;

  > * {
    width: 90px;
    margin-right: $xl;
    box-sizing: border-box;
  }
}

.reportSelectDropdown {
  margin-bottom: 24px;
}

.reportSelectDropdownHelpText {
  color: $teal-1;
  font-family: WesFY-Bold;
}

.reportSelectDropdownHelpTextContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: $s;
}

.learnAboutCustomReportsBtn {
  color: $blue-2;
  font-family: WesFY-Bold;
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
}
