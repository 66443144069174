@import "~styles/variables";
@import "../common.scss";

.day {
  @include focus();
}

.inMonthDay {
  background-color: $gray-5;
}
