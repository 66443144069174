@import "~styles/variables";

.tabs {
  display: flex;
  min-height: 39px;
  box-sizing: border-box;
  margin-bottom: 16px;

  @include mobile {
    border: 1px solid $gray-3;
    border-radius: 8px;
  }
}

.compactTabs {
  composes: tabs;
  min-height: 25px;
}

.tab {
  font-family: $WesFY-Bold;
  font-size: 24px;
  font-weight: 900;
  text-align: left;
  margin-right: $xl;
  color: $gray-3;
  border-bottom: 5px solid transparent;
  cursor: pointer;

  @include mobile {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 55px;
    border-radius: 7.5px;
    border: none;
    margin: 0;
    color: $white;
    padding: unset;
    font-size: 18px;

    &.activeTab {
      background-color: $teal-1;
      color: $white;
    }
  }
}

.invertedBg {
  @include mobile {
    color: $gray-3;
  }
}

.activeTab {
  color: $teal-1;
  border-bottom-color: currentColor;
}

.compactTab {
  font-size: 14px;
  padding-bottom: $xxs;

  &.activeTab {
    border-bottom-width: 3px;
  }
}
