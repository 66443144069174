@import "~styles/variables";

.emptyState {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.roleName {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  color: $teal-1;
}

.selectARole {
  margin-top: $xs;
}

.gigOptionCheckbox {
  margin: $m $m;
  border-bottom: 0;
}
